import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { environment } from '@environment'
import { Observable } from 'rxjs'
import { FaqInterface } from '../interface/faq.interface'

@Injectable({
   providedIn: 'root',
})
export class MoedasFaqService {
   constructor(
      private httpClient: HttpClient,
      @Inject('moedasEnv')
      private moedasEnv: typeof environment,
   ) { }

   public getFaq(): Observable<FaqInterface> {
      const headers = new HttpHeaders()
      return this.httpClient.get<FaqInterface>(`${this.moedasEnv.apiUrl}faq-sections`, { headers })
   }
}
