import { environment } from '@environment'
import { Observable, shareReplay } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient, HttpContext } from '@angular/common/http'
import { Department } from '../../core/interface/department.interface'
import { HAS_LOADING } from '@helpers/constants/contexts.helper'

const API = environment.apiUrl

@Injectable({
   providedIn: 'root',
})
export class DepartmentService {
   constructor(private httpClient: HttpClient) {}

   getDepartments(): Observable<Department> {
      return this.httpClient
         .get<Department>(`${API}departments`, {
            context: new HttpContext().set(HAS_LOADING, true),
         })
         .pipe(shareReplay())
   }
}
