<header #headerMenu class="moedas-header hide-print">
   <div [ngClass]="{ 'moedas-header__container--logged': !auth }" class="moedas-header__container">
      <div class="moedas-menu-mobile">
         <burguer-menu
            (click)="toggleMobileMenu()"
            (opened)="(menuOpened)"
            [active]="menuOpened"
         ></burguer-menu>
      </div>

      <div
         [ngClass]="{
            'moedas-menu-mobile-container--mobile-on': menuOpened
         }"
         class="moedas-menu-mobile-container"
      >
         <div class="moedas-menu-mobile-container-auth" *ngIf="auth; else noAuth">
            <div
               class="moedas-menu-mobile-container-auth__container"
               *ngIf="hasProfileUser?.store?.length > 0"
            >
               <i class="material-icons" *ngIf="!userData?.profilePhoto">account_circle</i>
               <img
                  *ngIf="!!userData?.profilePhoto"
                  class="user-avatar"
                  [src]="userData?.profilePhoto"
                  alt="foto perfil"
                  aria-hidden="true"
               />
               <div
                  (click)="goToProfile()"
                  class="moedas-menu-mobile-container-auth__container-info"
               >
                  <p>{{ firstName }} {{ userData?.surname }}</p>
                  <div class="level-user">
                     <div class="level-user__container">
                        <span>{{ hasProfileUser?.store[0]?.stats[0]?.level.label }}</span>
                        <strong>{{ hasProfileUser?.store[0]?.stats[0]?.totalSales }}</strong>
                        <img src="assets/img/arrow-right-2.svg" alt="" />
                     </div>
                     <div class="level__stars">
                        <span
                           *ngFor="let _ of [].constructor(5); let i = index"
                           [style.background]="
                              i + 1 <= hasProfileUser?.store[0]?.stats[0]?.rating && '#1251ff'
                           "
                        ></span>
                     </div>
                  </div>
               </div>
            </div>
            <hr class="user-hr" *ngIf="hasProfileUser?.store?.length > 0" />
            <div class="list-user">
               <nav>
                  <ng-container *ngFor="let route of routes">
                     <accordion [opened]="false">
                        <div header class="side-menu-header">
                           <p>
                              {{ route.title }}
                           </p>
                        </div>
                        <ng-container content>
                           <ul class="side-menu-router-list">
                              <hr class="hr-user" />
                              <ng-container *ngFor="let child of route.childs">
                                 <li
                                    [routerLink]="child.link"
                                    routerLinkActive="active"
                                    (click)="
                                       pageName = child.name;
                                       this.menuOpened = !this.menuOpened;
                                       resetScrool()
                                    "
                                 >
                                    {{ child.name }}
                                 </li>
                              </ng-container>
                           </ul>
                        </ng-container>
                     </accordion>
                  </ng-container>
               </nav>
               <div class="offers">
                  <div class="offers-title">
                     <h2>Departamentos</h2>
                  </div>
                  <div class="moedas-department-cards-header">
                     <div class="moedas-department-cards-header__container">
                        <ng-container *ngFor="let item of department">
                           <app-cards (closeMenu)="close($event)" [departaments]="item"></app-cards>
                        </ng-container>
                     </div>
                  </div>
                  <hr class="dep-hr" />
                  <div class="exit" style="cursor: pointer" (click)="deslogar()">
                     <img src="assets/img/exit.svg" alt="Sair" />
                     <p>Sair</p>
                  </div>
               </div>
            </div>
         </div>

         <ng-template #noAuth>
            <div class="no-auth">
               <p>Olá! Faça o login ou entre na sua conta</p>
               <button [routerLink]="['/cadastre-se']" role="button">Faça sua conta</button>
            </div>
            <hr />
            <div class="offers">
               <div class="offers-title">
                  <h2>Departamentos</h2>
               </div>
               <div class="moedas-department-cards-header">
                  <div class="moedas-department-cards-header__container">
                     <ng-container *ngFor="let item of department">
                        <app-cards (closeMenu)="close($event)" [departaments]="item"></app-cards>
                     </ng-container>
                  </div>
               </div>
            </div>
         </ng-template>
      </div>
      <a [routerLink]="['/']">
         <img src="assets/img/Logo.svg" alt="Moedas.com.br logo" />
      </a>
      <form class="search" (submit)="submitSearch($event)">
         <app-search-input (changeSearch)="searchField($event.target.value)"></app-search-input>
      </form>
      <div class="moedas-header__container-category" (click)="arrowToggleDep()">
         <div class="moedas-header__container-category-dep">
            <a>Departamentos</a>
            <arrow [type]="'bottom'" [active]="depMenu"></arrow>
         </div>
         <div
            class="dropdown-dep"
            (clickOutside)="depMenu = false"
            [@openAnimation]
            *ngIf="depMenu"
            [ngClass]="{ 'dropdown-dep--active': depMenu }"
         >
            <ul>
               <ng-container *ngFor="let item of departmentMenu">
                  <li
                     [title]="item?.displayName"
                     [routerLink]="['/busca']"
                     [queryParams]="{ 'department.name.keyword': item?.name }"
                  >
                     {{ item?.displayName }}
                  </li>
               </ng-container>
            </ul>
         </div>
      </div>
      <a class="moedas-header__container-advertise m-right" (click)="advertiseRoute()">Anuncie</a>
      <div class="box-auth" *ngIf="auth; else notAuth">
         <div class="box-auth__container">
            <i class="material-icons favorite" (click)="goToFavorite()">favorite_border</i>
            <div class="box-notification">
               <span *ngIf="hasIdProduct" class="box-notification-msg"></span>
               <img
                  class="box-notification-shop-cart"
                  [routerLink]="['/carrinho']"
                  src="assets/img/shop_cart.svg"
               />
            </div>
            <div class="moedas-header__container-user" (click)="arrowToggle()">
               <i class="material-icons" *ngIf="!userData?.profilePhoto">account_circle</i>
               <img
                  *ngIf="!!userData?.profilePhoto"
                  class="user-avatar"
                  [src]="userData?.profilePhoto"
                  alt="foto perfil"
               />
               <p>{{ firstName }}</p>
               <arrow [type]="'bottom'" [active]="menuUser"></arrow>
            </div>
         </div>

         <div
            (clickOutside)="menuUser = false"
            [@openAnimation]
            class="dropdown-user"
            *ngIf="menuUser"
         >
            <ng-container *ngIf="hasProfileUser?.store?.length > 0">
               <p class="dropdown-user-info">
                  {{ hasProfileUser?.store[0]?.stats[0]?.level.label }}
                  <strong>{{ hasProfileUser?.store[0]?.stats[0]?.totalSales }}</strong>
                  <img
                     (click)="menuUser = !menuUser; goToProfile()"
                     src="assets/img/ico-arrow-right.svg"
                  />
               </p>
               <div class="level-stars">
                  <span
                     *ngFor="let _ of [].constructor(5); let i = index"
                     [style.background]="
                        i + 1 <= hasProfileUser?.store[0]?.stats[0]?.rating && '#1251ff'
                     "
                  ></span>
               </div>
               <hr class="dropdown-user-hr" />
            </ng-container>

            <ul class="dropdown-user-nav">
               <ng-container *ngFor="let item of navMenuItems; let i = index">
                  <li (click)="menuUser = !menuUser" [routerLink]="['/' + item.rota]">
                     {{ item.nav }}
                  </li>
               </ng-container>
            </ul>
            <hr class="dropdown-user-hr" />
            <div class="dropdown-user-exit">
               <span style="cursor: pointer" (click)="deslogar()">
                  <img src="assets/img/exit.svg" alt="" />
                  <a>Sair</a>
               </span>
            </div>
         </div>
      </div>

      <button
         class="moedas-header__container-search"
         [ngClass]="{
            'moedas-header__container-search--arrow': searchInputOpened
         }"
         (click)="this.toggleSearchBar()"
      ></button>

      <ng-template #notAuth>
         <a class="moedas-header__container-account" [routerLink]="['/cadastre-se']" role="link"
            >Faça a sua conta</a
         >
         <button (click)="signIn()" role="button">Entrar</button>
      </ng-template>
   </div>
</header>

<search-mobile class="hidden-search" [toggle]="searchInputOpened"></search-mobile>
