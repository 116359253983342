import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule, CurrencyPipe } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { RouterModule } from '@angular/router'
import { AlertsComponent } from '@core/components/alerts/alerts.component'
import { AnswerModalComponent } from '@core/components/answer-modal/answer-modal.component'
import player from 'lottie-web'
import { LottieAnimationViewModule } from 'ng-lottie'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { LottieModule } from 'ngx-lottie'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { NgxPaginationModule } from 'ngx-pagination'
// Lib Swiper
import { SwiperModule } from 'swiper/angular'
import { CardsComponent } from '../core/components/department-cards/cards/cards.component'
import { DepartmentCardsComponent } from '../core/components/department-cards/department-cards.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { AccountStructureComponent } from './components/account-structure/account-structure.component'
import { ArrowAnimatedComponent } from './components/arrow-animated/arrow-animated.component'
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component'
// Layout Shared
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { CardStatusComponent } from './components/card-status/card-status.component'
import { DragDropFileComponent } from './components/drag-drop-file/drag-drop-file.component'
import { ErrorMessageComponent } from './components/error-message/error-message.component'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { SearchMobileComponent } from './components/header/search-mobile/search-mobile.component'
import { InputsComponent } from './components/inputs/inputs.component'
import { LoadingSpinComponent } from './components/loading-spin/loading-spin.component'
import { MoedasLoadingComponent } from './components/loading/moedas-loading/moedas-loading.component'
import { NotFoundMessageComponent } from './components/not-found-message/not-found-message.component'
import { OptionsMenuComponent } from './components/options-menu/options-menu.component'
import { OptionsMenuDirective } from './components/options-menu/options-menu.directive'
import { OrdenationMenuComponent } from './components/ordenation-menu/ordenation-menu.component'
import { ProductCardComponent } from './components/product-card/product-card.component'
import { SearchInputComponent } from './components/search-input/search-input.component'
import { ToastComponent } from './components/toast/toast.component'
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive'
import { DropzoneDirective } from './directives/drop-zone/dropzone.directive'
import { TooltipModule } from './directives/tooltip/tooltip.module'
// Pipes
import { CapitalizeCasePipe } from './pipes/capitalize-case.pipe'
import { EllipsisPipe } from './pipes/ellipsis.pipe'
import { FilterPipe } from './pipes/filter.pipe'
import { SafePipe } from './pipes/safe.pipe'
import { StatusPipe } from './pipes/status.pipe'
import { TotalValuePipe } from './pipes/total-value.pipe'

// Material
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { BecomeSellerComponent } from './components/become-seller/become-seller.component'
import { FreeInputsComponent } from './components/free-inputs/free-inputs.component'
import { BurguerMenuComponent } from './components/header/components/burguer-menu/burguer-menu.component'
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component'
import { BorderColorPipe } from './pipes/border-color.pipe'
import { HttpSafeImagePipe } from './pipes/http-safe-image.pipe'
import { SelectDataPipe } from './pipes/select-data.pipe'

import { CKEditorModule } from 'ckeditor4-angular'
import { RoundPipe } from './pipes/round.pipe'
import { MOEDAS_TOKEN } from '@helpers/constants/providers.helper'
import { SelectInstallmentsPipe } from './pipes/select-installments.pipe'
import { SkeletonComponent } from './components/skeleton/skeleton.component'
import { PageSuccessComponent } from './components/pages/page-success/page-success.component'

const maskConfig: Partial<IConfig> = {
   validation: false,
}

export function playerFactory() {
   return player
}

@NgModule({
   declarations: [
      FooterComponent,
      HeaderComponent,
      BreadcrumbComponent,
      ProductCardComponent,
      SearchInputComponent,
      DepartmentCardsComponent,
      CardsComponent,
      ErrorMessageComponent,
      FilterPipe,
      InputsComponent,
      NotFoundMessageComponent,
      AlertsComponent,
      ClickOutsideDirective,
      MoedasLoadingComponent,
      CapitalizeCasePipe,
      AccordionComponent,
      ArrowAnimatedComponent,
      AccountStructureComponent,
      BaseDialogComponent,
      DragDropFileComponent,
      DragDropFileComponent,
      DropzoneDirective,
      SafePipe,
      LoadingSpinComponent,
      OptionsMenuComponent,
      OptionsMenuDirective,
      TotalValuePipe,
      StatusPipe,
      EllipsisPipe,
      SearchMobileComponent,
      ToastComponent,
      AnswerModalComponent,
      OrdenationMenuComponent,
      CardStatusComponent,
      SelectDataPipe,
      TransactionsTableComponent,
      BorderColorPipe,
      BecomeSellerComponent,
      FreeInputsComponent,
      BurguerMenuComponent,
      HttpSafeImagePipe,
      RoundPipe,
      SelectInstallmentsPipe,
      SkeletonComponent,
      PageSuccessComponent,
   ],
   imports: [
      CommonModule,
      SwiperModule,
      RouterModule,
      ReactiveFormsModule,
      NgxMaskModule.forRoot(maskConfig),
      LottieAnimationViewModule.forRoot(),
      RouterModule,
      LottieModule.forRoot({ player: playerFactory }),
      NgxPaginationModule,
      OverlayModule,
      TooltipModule,
      MatSnackBarModule,
      CurrencyMaskModule,
      MatSelectModule,
      MatAutocompleteModule,
      MatFormFieldModule,
      MatInputModule,
      CKEditorModule,
   ],
   exports: [
      HeaderComponent,
      FooterComponent,
      BreadcrumbComponent,
      ProductCardComponent,
      SearchInputComponent,
      SwiperModule,
      DepartmentCardsComponent,
      CardsComponent,
      ErrorMessageComponent,
      FilterPipe,
      InputsComponent,
      NgxMaskModule,
      NotFoundMessageComponent,
      AlertsComponent,
      ReactiveFormsModule,
      ClickOutsideDirective,
      LottieAnimationViewModule,
      MoedasLoadingComponent,
      NgxPaginationModule,
      CapitalizeCasePipe,
      AccordionComponent,
      ArrowAnimatedComponent,
      AccountStructureComponent,
      OverlayModule,
      BaseDialogComponent,
      DragDropFileComponent,
      DropzoneDirective,
      SafePipe,
      LoadingSpinComponent,
      OptionsMenuComponent,
      OptionsMenuDirective,
      TotalValuePipe,
      StatusPipe,
      EllipsisPipe,
      TooltipModule,
      ToastComponent,
      MatSnackBarModule,
      OrdenationMenuComponent,
      CardStatusComponent,
      CurrencyMaskModule,
      MatSelectModule,
      SelectDataPipe,
      TransactionsTableComponent,
      BorderColorPipe,
      BecomeSellerComponent,
      MatAutocompleteModule,
      FreeInputsComponent,
      HttpSafeImagePipe,
      CKEditorModule,
      RoundPipe,
      SelectInstallmentsPipe,
      SkeletonComponent,
      PageSuccessComponent,
   ],
   providers: [{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
   public static forRoot(args: any): ModuleWithProviders<SharedModule> {
      return {
         ngModule: SharedModule,
         providers: [
            {
               provide: MOEDAS_TOKEN,
               useValue: args.env,
            },
            CurrencyPipe,
         ],
      }
   }
}
