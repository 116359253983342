import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'

@Component({
   selector: 'app-cards',
   templateUrl: './cards.component.html',
   styleUrls: ['./cards.component.scss'],
})
export class CardsComponent {
   @Input() departaments: any
   @Input() navigation: boolean = true
   @Output() closeMenu = new EventEmitter<boolean>()
   @Output() selected = new EventEmitter<string>()

   constructor(private router: Router) {}

   routerLink(department: any) {
      if (this.navigation) {
         this.router.navigate(['busca'], {
            queryParams: { 'department.name.keyword': department?.name },
         })
      } else {
         this.selected.emit(department)
      }
   }
}
