import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { ESessionStorage } from '@enums/localstorage.enum'

@Injectable({
   providedIn: 'root',
})
export class GetValuesInSessionService {
   public hasSession = JSON.parse(sessionStorage.getItem(ESessionStorage.SAVE_CART) || '{}')

   private _values$: BehaviorSubject<any> = new BehaviorSubject<any>(this.hasSession)

   readonly values$: Observable<any> = this._values$.asObservable()

   private _hasProducts$: BehaviorSubject<boolean> = new BehaviorSubject<any>(true)

   readonly hasProducts$: Observable<boolean> = this._hasProducts$.asObservable()

   setValues(value: any): void {
      const checkArray = Object.values(value).every((element: any) => element !== null)
      if (checkArray) {
         sessionStorage.setItem(ESessionStorage.SAVE_CART, JSON.stringify(value))
      } else {
         sessionStorage.removeItem(ESessionStorage.SAVE_CART)
         this._values$.next(null)
      }
      this._values$.next(value)
   }

   verifyProduct(hasProduct: boolean): void {
      this._hasProducts$.next(hasProduct)
   }
}
