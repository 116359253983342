/* istanbul ignore file */
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser, Location } from '@angular/common'
import { Router } from '@angular/router'

import { BehaviorSubject } from 'rxjs'
import { LocalstorageService } from '@helpers/utils/localstorage'
import { ELocalStorage } from '@enums/localstorage.enum'
import * as LogRocket from 'logrocket'
@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
   public emptyOutlet: boolean = false

   public showHeader: boolean = true

   public showFooter: boolean = true

   public loading: boolean = true

   static isBrowser = new BehaviorSubject<boolean>(null as any)

   /**
    * Usado para retirar header e footer de certas páginas
    *
    * @param router Parâmetro da rota
    */
   constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      private router: Router,
      private location: Location,
      private localStorage: LocalstorageService,
   ) {
      AppComponent.isBrowser.next(isPlatformBrowser(platformId))
   }

   ngOnInit() {
      window.Safe2Pay.Init('P95B58BC2CD')
      this.checkSession()
      this.logRocketIdentify()
      this.router.events.subscribe(_ => {
         const location: string = this.location.path()
         const hasRoute = [
            'login',
            'cadastre-se',
            'carrinho',
            'recuperar-senha',
            'confirmacao-email',
            'cadastro-sucesso',
         ].includes(location.split('/')?.[1])

         if (hasRoute) {
            this.showFooter = false
            this.showHeader = false
         } else {
            this.showFooter = true
            this.showHeader = true
         }
      })
   }

   checkSession(): void | null {
      let itemStr = this.localStorage.getItem(ELocalStorage.USER_TOKEN)

      if (!itemStr) {
         return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      if (now.getTime() > item.expiry || !item.expiry) {
         this.localStorage.clear()

         return null
      }
   }

   logRocketIdentify(): void {
      const user = this.localStorage.getItem(ELocalStorage.USER_DATA)

      if (user) {
         LogRocket.init('m64anm/moedascombr')
         const parsedUser = JSON.parse(user)
         LogRocket.identify(parsedUser.id, {
            name: `${parsedUser.name} ${parsedUser.surname}`,
         })
      }
   }
}
