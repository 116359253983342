import { Component, Input } from '@angular/core'
import { ModalService } from '@global-services/dialog/modal.service'
import { SendDocumentsComponent } from './send-documents/send-documents.component'

@Component({
   selector: 'become-seller',
   templateUrl: './become-seller.component.html',
   styleUrls: ['./become-seller.component.scss'],
})
export class BecomeSellerComponent {
   @Input() userData: any
   constructor(private dialog: ModalService) {}

   OpenModal() {
      this.dialog
         .open(SendDocumentsComponent, { panelClass: 'documents-modal' })
         .afterClosed()
         .subscribe(result => {
            if (result) {
               this.userData.storeRequest = result
            }
         })
   }
}
