import { Component, Input } from '@angular/core'

@Component({
   selector: 'not-found-message',
   templateUrl: './not-found-message.component.html',
   styleUrls: ['./not-found-message.component.scss'],
})
export class NotFoundMessageComponent {
   @Input() icon: string
   @Input() title: string
   @Input() text: string
   @Input() secondText: string

   constructor() {}
}
