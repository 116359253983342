import { environment } from '@environment'
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HAS_LOADING } from '@helpers/constants/contexts.helper'
import { Observable } from 'rxjs'
import { ApiUserQuestions } from '../interface/user-question.interface'

@Injectable({
   providedIn: 'root',
})
export class QuestionsService {
   readonly apiUrl: string = environment.apiUrl
   constructor(private httpClient: HttpClient) {}

   public getStoreQuestions(data: any, globalLoading: boolean): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.post<any>(`${this.apiUrl}products-questions/store`, data, {
         headers,
         context: new HttpContext().set(HAS_LOADING, globalLoading),
      })
   }

   public getUserQuestions(data: any, globalLoading: boolean): Observable<ApiUserQuestions> {
      const headers = new HttpHeaders()
      return this.httpClient.post<ApiUserQuestions>(`${this.apiUrl}products-questions/user`, data, {
         headers,
         context: new HttpContext().set(HAS_LOADING, globalLoading),
      })
   }

   public answerUserQustion(data: any): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.post<any>(`${this.apiUrl}products-questions/answer`, data, {
         headers,
      })
   }

   public makeQuestion(dataAsk: any): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.post<any>(`${this.apiUrl}products-questions`, dataAsk, {
         headers,
      })
   }
}
