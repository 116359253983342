<div class="adverts__become-seller">
   <h2>Seja agora um vendedor</h2>

   <p class="text">
      Anuncie seus produtos, tenha mais visibilidade e impulsione suas vendas. Você não paga nada pra se cadastrar e, entre vários outros benefícios, recebe integralmente mesmo que o cliente compre optando por pagamento parcelado. 
      <br>
      <br>
      É fácil, rápido e seguro!
      <br>
      <br>
      Faça upload dos arquivos necessários pro seu cadastro. Identidade, CPF e comprovante de residência. 
   </p>

   <button *ngIf="!userData?.storeRequest" (click)="OpenModal()">Quero ser um vendedor</button>
   <div *ngIf="userData?.storeRequest" class="adverts__become-seller-status">
      <div class="adverts__become-seller-status-user">
         <div class="adverts__become-seller-status-image">
            <i class="material-icons">account_circle</i>
            <span class="adverts__become-seller-status-title">
               <strong>{{ userData?.name }} {{ userData?.surname }}</strong>
               <small
                  >{{ userData?.address?.[0]?.city }} - {{ userData?.address?.[0]?.state }}</small
               >
            </span>
         </div>
         <span class="adverts__become-seller-status-info">
            <p>Status</p>
            <strong>Em análise</strong>
         </span>
      </div>
      <p class="adverts__become-seller-status-desc">
         Seus documentos estão em análise. Por favor, Aguarde.
      </p>
   </div>
</div>
