import { Pipe, PipeTransform } from '@angular/core'

interface SelectData {
   value: string | number
   viewValue: string | number
}

@Pipe({
   name: 'selectData',
})
export class SelectDataPipe implements PipeTransform {
   transform(
      value: any,
      param1: string = 'installments',
      param2: string = 'recommended_message',
      param3: string = 'installment_rate',
   ): SelectData {
      return value?.map((el: any) => {
         const selectValue = {
            value: el[param1],
            viewValue:
               param1 === 'installments'
                  ? `<p style="padding: 5px 0">${el[param2]} ${
                       el.installment_rate > 0 ? '<small>' + el[param3] + '% de juros' : 'sem juros'
                    }</small></p>`
                  : el[param2],
         }
         return selectValue
      })
   }
}
