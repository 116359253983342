<div class="container-animation">
   <div *ngIf="skeletonType === 'card'; else rect" class="skeleton"></div>
   <ng-template #rect>
      <div class="lines">
         <div class="lines-skeleton"></div>
         <div class="lines-skeleton"></div>
         <div class="lines-skeleton"></div>
      </div>
   </ng-template>
</div>
