<div class="toast">
   <p class="toast__title">{{ content?.title }}</p>
   <button class="toast__close" (click)="onClose()">
      <span class="material-icons">clear</span>
   </button>
   <div class="toast__mgs">
      <span class="material-icons icon error" *ngIf="content.type === 'error'">cancel</span>
      <span class="material-icons icon success" *ngIf="content.type === 'sucesss'">
         check_circle
      </span>
      <p class="toast__mgs-txt">{{ content?.text }}</p>
   </div>
</div>
