import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'round',
})
export class RoundPipe implements PipeTransform {
   transform(value: any): any {
      if (!isNaN(value)) {
         return Math.round(value)
      } else {
         return value
      }
   }
}
