<div class="card-satus">
   <div class="card-satus__head">
      <strong>Atualizado em {{ content.updatedAt | date: 'longDate' | capitalizecase }}</strong>
      <strong>PEDIDO Nº {{ content.id }}</strong>
   </div>
   <div class="card-satus__content">
      <span class="card-satus__content-info">
         <ng-content select="[info]"></ng-content>
      </span>
      <ng-content select="[info-mobile]"></ng-content>
   </div>
   <div
      class="card-satus__progressbar"
      [ngClass]="{ 'card-satus__progressbar--cancelled': content?.status === 'cancelled' }"
   >
      <div [style.width.%]="content?.status_data?.status_percent"></div>
   </div>

   <div class="scroll">
      <div class="card-satus__product scroll__roll">
         <ng-content select="[product-img]"></ng-content>

         <span class="card-satus__product-item">
            <ng-content select="[product]"></ng-content>
         </span>
      </div>
   </div>
</div>
