import { Router } from '@angular/router';
import { transition, trigger } from '@angular/animations'
import { Component, Input } from '@angular/core'
import * as animations from '@animations'
@Component({
   selector: 'search-mobile',
   templateUrl: './search-mobile.component.html',
   styleUrls: ['./search-mobile.component.scss'],
   animations: [
      trigger('openAnimation', [
         transition(':enter', animations.useHeightInAnimation('0.2s')),
         transition(':leave', animations.useHeightOutAnimation('0.2s')),
      ]),
   ],
})
export class SearchMobileComponent {
   @Input() toggle: boolean
   public search: string

   constructor(
      private router: Router
   ) {}

   submitSearch(event: any) {
      event.preventDefault()
      const termToSearch = this.search
      this.router.navigate([`/busca/${termToSearch}`])
   }

   searchField(data: any) {
      this.search = data
   }
}
