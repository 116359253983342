/* eslint-disable import/no-unresolved */
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CommonModule, registerLocaleData } from '@angular/common'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'

// Modules
import { RouterModule } from '@angular/router'
import { SharedModule } from './shared/shared.module'

// Components
import { NotFoundComponent } from './core/components/not-found/not-found.component'
import { MoedasFaqComponent } from './core/pages/moedas-faq/moedas-faq.component'

// Providers
import { MoedasHomeService } from './core/services/moedas-home.service'
import { MoedasFaqService } from './core/services/moedas-faq.service'
import { ProductService } from './core/services/product.service'
import { SearchService } from './core/services/search.service'

//Env
import { environment } from '../environments/environment'

import { AuthInterceptor } from './core/services/interceptors/auth.interceptor'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools'
import { LoadingInterceptor } from '@services/interceptors/loading.interceptor'
import { NgxSliderModule } from '@angular-slider/ngx-slider'

registerLocaleData(localePt)

@NgModule({
   declarations: [AppComponent, NotFoundComponent, MoedasFaqComponent],
   imports: [
      BrowserAnimationsModule,
      CommonModule,
      RouterModule,
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      SharedModule.forRoot({ env: environment }),
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      NgxSliderModule,
      environment.production ? [] : AkitaNgDevtools.forRoot(),
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoadingInterceptor,
         multi: true,
      },
      {
         provide: LOCALE_ID,
         useValue: 'pt-BR',
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true,
      },
      MoedasHomeService,
      MoedasFaqService,
      ProductService,
      SearchService,
   ],
   bootstrap: [AppComponent],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
