import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { Router } from '@angular/router'
import { LocalstorageService } from '@helpers/utils/localstorage'
import { AuthService } from '../../services/auth.service'

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
   public auth: boolean

   constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      private router: Router,
      private authService: AuthService,
      private localStorage: LocalstorageService,
   ) {}

   ngOnInit() {
      this.auth = this.authService.auth
   }

   deslogar() {
      this.authService.logout()
   }

   advertiseRoute() {
      if (!this.auth) {
         this.router.navigate(['/login'])
      } else {
         this.router.navigate(['vendas/anuncios'])
      }
   }
}
