<div class="drag-drop">
   <p class="drag-drop__label">{{ label }}</p>
   <input
      type="file"
      [accept]="accept"
      (change)="fileDropped($event)"
      hidden
      #file
      [multiple]="multiple"
   />
   <div class="drag-drop__card" dropzone (fileDropped)="fileDropped($event)">
      <ng-container *ngIf="!fileInfo; else fileLoading">
         <span class="drag-drop__card-items">
            <img
               (click)="file.click()"
               src="./../../../../assets/img/upload.svg"
               alt="upload icon"
               aria-hidden="true"
            />
            <p>
               <button type="button" (click)="file.click()">Clique aqui</button> ou {{ cardLabel }}
            </p>
         </span>

         <span class="drag-drop__card-items-size" [class.error]="hasError">
            Tamanho máximo de 10mb
         </span>
      </ng-container>

      <ng-template #fileLoading>
         <div class="drag-drop__file-status">
            <img
               (click)="file.click()"
               src="./../../../../assets/img/document.svg"
               alt="document icon"
               aria-hidden="true"
            />
            <span class="drag-drop__file-status-file-info">
               <span class="drag-drop__file-status-file-info-name">
                  <p>{{ fileInfo || '' | ellipsis: 30 }}</p>
                  <button type="button" aria-label="fechar botão" (click)="deleteFile()"></button>
               </span>
               <div class="drag-drop__file-status-progressbar">
                  <div role="progressbar" [style.width.%]="progress"></div>
               </div>
               <p class="drag-drop__file-status-file-info-status">{{ progress }}% concluído</p>
            </span>
         </div>
      </ng-template>
   </div>
</div>

<div class="max-images-error">
   <alert *ngIf="typeError" type="error" text="Arquivo inválido"> </alert>

   <alert *ngIf="!maxItems" type="warn" text="Máximo de 5 imagens"></alert>
</div>
