import { Injectable } from '@angular/core'
import { UserResponse } from '@interfaces/user.interface'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class SharedAuthService {
   private shared$: BehaviorSubject<Boolean | any> = new BehaviorSubject<Boolean | any>(null)

   readonly auth$: Observable<Boolean | any> = this.shared$.asObservable()

   setLoginOrLogout(auth: boolean) {
      this.shared$.next(auth)
   }

   private _userData$: BehaviorSubject<UserResponse | any> = new BehaviorSubject<
      UserResponse | any
   >(null)

   readonly userData$: Observable<UserResponse | any> = this._userData$.asObservable()
   setUserData(data: UserResponse | any) {
      this._userData$.next(data)
   }
}
