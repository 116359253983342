import { environment } from '@environment'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class UploadService {
   readonly api_url: string = environment.apiUrl
   constructor(private httpClient: HttpClient) {}

   public uploadFiles(data: any, type: string): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.post<any>(`${this.api_url}upload?type=${type}`, data, {
         headers,
         reportProgress: true,
         observe: 'events',
      })
   }

   public deleteFiles(data: any, type: string): Observable<any> {
      const headers = new HttpHeaders()
      const fromObject = {
         type: type,
         file: data,
      }
      const params = new HttpParams({ fromObject })
      return this.httpClient.delete<any>(`${this.api_url}upload`, {
         headers,
         params,
      })
   }
}
