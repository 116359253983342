<section class="sidebar-structure">
   <article class="sidebar-structure__container">
      <aside class="sidebar-structure__side-menu">
         <h1 class="sidebar-structure__side-menu-name">
            Seja bem vind{{ userData?.gender === 'male' ? 'o' : 'a' }},
            {{ userData?.name | titlecase }}!
         </h1>
         <nav class="side-menu">
            <ng-container *ngFor="let route of routes">
               <accordion [opened]="true">
                  <div header class="sidebar-structure__side-menu-header">
                     <p>
                        <!-- <i class="material-icons">{{ route.icon }}</i> {{ route.title }} -->
                        <img
                           [src]="'./../../../../assets/img/icon-' + route.icon + '.svg'"
                           alt="icon"
                        />
                        {{ route.title }}
                     </p>
                  </div>

                  <ng-container content>
                     <ul class="sidebar-structure__side-menu-router-list">
                        <ng-container *ngFor="let child of route.childs">
                           <li
                              *ngIf="child.active"
                              [routerLink]="child.link"
                              routerLinkActive="active"
                              (click)="pageName = child.name"
                           >
                              {{ child.name }}
                           </li>
                        </ng-container>
                     </ul>
                  </ng-container>
               </accordion>
            </ng-container>
         </nav>
      </aside>
      <div class="sidebar-structure__container-content">
         <ng-content select="[content]"></ng-content>
      </div>
   </article>
</section>
