<base-dialog [title]="!data.answer ? 'Responder pergunta' : 'Vizualizar resposta'">
   <div class="answer-question">
      <h2 class="answer-question__user">{{ data.user.name }} {{ data.user.surname }} perguntou:</h2>
      <small class="answer-question__question">{{ data.question }}</small>

      <form [formGroup]="validaFormGroup" class="answer-question__form" (ngSubmit)="sendAnswer()">
         <div class="form-control">
            <inputs
               input_type="textarea"
               label="Resposta"
               placeholder="Digite sua resposta"
               NameformControl="answer"
               [submitted]="submitted"
               [rows]="5"
            ></inputs>
         </div>

         <button
            role="button"
            id="submit"
            type="submit"
            title="responder pergunta"
            class="btn-loader answer-question__form-button"
            [class.btn-loader--start]="isLoading"
            [disabled]="data.answer"
         >
            Enviar resposta
         </button>
      </form>
   </div>
</base-dialog>
