import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { QuestionsService } from '@services/questions.service'
import { ToastComponent } from '@shared/components/toast/toast.component'
import { DialogRef } from '@shared/services/dialog/dialog-refs'
import { DIALOG_DATA } from '@shared/services/dialog/modal.service'
import { first, Subscription } from 'rxjs'

@Component({
   selector: 'app-answer-modal',
   templateUrl: './answer-modal.component.html',
   styleUrls: ['./answer-modal.component.scss'],
})
export class AnswerModalComponent implements OnInit, OnDestroy {
   public validaFormGroup: FormGroup
   public subscriptions: Subscription[] = []
   public submitted = false
   public isLoading: boolean = false
   constructor(
      @Inject(DIALOG_DATA) public data: any,
      private dialogRef: DialogRef,
      private questionsService: QuestionsService,
      private snackBar: MatSnackBar,
   ) {}

   ngOnInit() {
      this.buildForm()
   }

   /* istanbul ignore next */
   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   private buildForm() {
      this.validaFormGroup = new FormGroup({
         id: new FormControl(this.data.id || ''),
         answer: new FormControl(this.data.answer || '', Validators.required),
      })
   }

   sendAnswer() {
      if (this.validaFormGroup.valid) {
         this.isLoading = true
         this.subscriptions.push(
            this.questionsService
               .answerUserQustion(this.validaFormGroup.value)
               .pipe(first())
               .subscribe({
                  next: _ => {
                     this.dialogRef.close(true)
                  },
                  error: error => {
                     console.error(error)
                     this.isLoading = false
                     this.openToast()
                  },
               }),
         )
      }
   }

   openToast() {
      this.snackBar.openFromComponent(ToastComponent, {
         verticalPosition: 'bottom',
         data: { title: 'Ocorreu um erro!' },
         horizontalPosition: 'right',
      })
   }
}
