import { transition, trigger } from '@angular/animations'
import {
   AfterViewInit,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   ViewChild,
} from '@angular/core'
import * as animations from '@animations'
import { WindowSizeService } from '@helpers/utils/window-size'
import { BehaviorSubject, delay, Subscription } from 'rxjs'
@Component({
   selector: 'accordion',
   templateUrl: './accordion.component.html',
   styleUrls: ['./accordion.component.scss'],
   animations: [
      trigger('openAnimation', [
         transition(':enter', animations.useHeightInAnimation('0.25s')),
         transition(':leave', animations.useHeightOutAnimation('0.25s')),
      ]),
   ],
})
export class AccordionComponent implements OnInit, OnDestroy, AfterViewInit {
   @ViewChild('content', { static: false }) content: ElementRef
   @Input() icon: 'left' | 'right' | 'bottom' | 'top' = 'bottom'
   @Input() opened: boolean
   @Input() showButton: boolean = true
   @Input() ellipse: number[] = [30, 85]
   @Input() type: 'accordion' | 'expand' = 'accordion'
   @Output() booleanEvent = new EventEmitter<boolean>()
   public animate$ = new BehaviorSubject<boolean>(true)
   public subscriptions: Subscription[] = []
   public fullText: string
   public expandedText: boolean = false
   public text: any
   public size: number
   constructor(private windowSizeService: WindowSizeService) {}

   ngOnInit() {
      this.subscriptions.push(
         this.animate$.pipe(delay(100)).subscribe(_ => this.animate$.next(false)),
      )
   }

   ngAfterViewInit() {
      this.text = this.content?.nativeElement.querySelector('#text')
      this.fullText = this.text?.textContent

      this.subscriptions.push(
         this.windowSizeService.valid_size$.subscribe(res => {
            if (res) {
               this.size = this.ellipse[0]
            } else {
               this.size = this.ellipse[1]
            }

            if (!!this.fullText) {
               this.ellipseText()
            }
         }),
      )
   }
   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   private ellipseText() {
      this.text.textContent = `${this.fullText.substring(0, this.size)}...`
   }

   toggleAccordion(element: HTMLElement) {
      this.opened = !this.opened
      element.setAttribute('aria-expanded', this.opened.toString())
   }

   expandText() {
      this.expandedText = !this.expandedText
      this.booleanEvent.emit(this.expandedText)

      if (this.expandedText) {
         let newText: string = this.text?.textContent.replace('...', '')
         newText += this.fullText.substring(this.size, this.fullText.length)

         this.text.textContent = newText
      } else {
         this.ellipseText()
      }
   }
}
