import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'filter',
   pure: false,
})
export class FilterPipe implements PipeTransform {
   transform(array: any, filter: any, multi?: boolean): any {
      if (!array) return []
      if (!filter) return array

      if (!multi) return this.searchItems(array, filter)
      else return this.searchItemsMulti(array, filter)
   }

   private searchItems(array: any[], filter: any) {
      return array.filter(el => {
         return Object.keys(filter).every(propertyName => {
            /* istanbul ignore next */
            const elArgs = el[propertyName]
               ? el[propertyName].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
               : ''
            const filterArgs = filter[propertyName].replace(/[^a-zA-Z0-9]/g, '').toLowerCase()

            return elArgs.indexOf(filterArgs) != -1
         })
      })
   }

   private searchItemsMulti(array: any[], filter: any) {
      return array.filter(item => {
         return Object.keys(filter).some(keyName => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === ''
         })
      })
   }
}
