import { Component, OnInit } from '@angular/core'
import { ELocalStorage } from '@enums/localstorage.enum'
import { LocalstorageService } from '@helpers/utils/localstorage'
import { ProfileUserService } from '@shared/services/profile-user.service'

@Component({
   selector: 'account-structure',
   templateUrl: './account-structure.component.html',
   styleUrls: ['./account-structure.component.scss'],
})
export class AccountStructureComponent implements OnInit {
   public userData: any
   public pageName: string
   public routes: any = [
      {
         title: 'Minha conta',
         icon: 'user',
         childs: [
            {
               link: '../minha-conta/meus-dados',
               name: 'Meus dados',
               active: true,
            },
            {
               link: '../minha-conta/minha-loja',
               name: 'Minha loja',
               active: false,
            },
            {
               link: '../minha-conta/meus-enderecos',
               name: 'Meus endereços',
               active: true,
            },
            {
               link: '../minha-conta/meus-pagamentos',
               name: 'Meus pagamentos',
               active: true,
            },
            {
               link: '../minha-conta/contas-bancarias',
               name: 'Contas Bancárias',
               active: false,
            },
         ],
      },
      {
         title: 'Compras',
         icon: 'bagg',
         childs: [
            {
               link: '../compras/minhas-compras',
               name: 'Minhas compras',
               active: true,
            },
            {
               link: '../compras/meus-favoritos',
               name: 'Favoritos',
               active: true,
            },
            {
               link: '../compras/minhas-perguntas',
               name: 'Minhas Perguntas',
               active: true,
            },
         ],
      },
      {
         title: 'Vendas',
         icon: 'seller',
         childs: [
            {
               link: '../vendas/anuncios',
               name: 'Anúncios',
               active: true,
            },
            {
               link: '../vendas/minhas-vendas',
               name: 'Vendas',
               active: true,
            },
            {
               link: '../vendas/transacoes',
               name: 'Transações',
               active: true,
            },
            {
               link: '../vendas/perguntas',
               name: 'Perguntas',
               active: true,
            },
         ],
      },
   ]
   constructor(
      private localStorage: LocalstorageService,
      private profileUserService: ProfileUserService,
   ) { }

   ngOnInit() {
      this.userData = JSON.parse(this.localStorage.getItem(ELocalStorage.USER_DATA) || '{}')
      if (!this.userData.enableStore) {
         this.routes[2].childs[1].active = false
         this.routes[2].childs[2].active = false
         this.routes[2].childs[3].active = false
         this.routes[2].childs[0].name = 'Seja um vendedor'
      } else {
         this.routes[0].childs[4].active = true
         this.routes[0].childs[1].active = true
      }
   }
}
