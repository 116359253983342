import { Inject, Injectable, Input } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ApiResponse } from '../interface/api.interface'
import { environment } from '@environment'
@Injectable()
export class SearchService {
   constructor(
      private httpClient: HttpClient,
      @Inject('moedasEnv')
      private moedasEnv: typeof environment,
   ) { }

   @Input() selectedFilter: string = 'plan.id:desc'

   filterList = [
      {
         label: 'Mais relevantes',
         value: 'plan.id:desc',
      },
      {
         label: 'Maior valor',
         value: 'value:asc',
      },
      {
         label: 'Menor valor',
         value: 'value:desc',
      },
      {
         label: 'Mais vendidos',
         value: 'year:desc',
      },
   ]

   public submitSearch({ title, filters, order, initialSearch, from, size }: any): Observable<ApiResponse> {

      return this.httpClient.post<ApiResponse>(`${this.moedasEnv.apiUrl}products/search`, {
         filters: {
            from: from || 0,
            size: size || 15,
            query: {
               bool: {
                  must: title
                     ? {
                        multi_match: {
                           query: title,
                           fields: [
                              'title',
                              'description',
                              'department.displayName',
                              'manufacturer.displayName',
                           ],
                        },
                     }
                     : null,
                  filter: filters ? filters : [],
               },
            },
         },
         sort: [order || this.selectedFilter],
         initialSearch
      })
   }
}
