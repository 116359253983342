<section class="success">
   <div class="success-header">
      <img routerLink="/" src="assets/img/logo-white.svg" />
      <img src="assets/img/moedas-header.svg" />
   </div>
   <div class="success__container">
      <div class="success__container-form">
         <section class="success-msg">
            <h1 class="title">Sua conta foi criada com sucesso!</h1>
            <p>
               Parabéns! Você faz parte do Moedas.com.br. Agora precisamos validar a sua conta.
               Acesse a caixa de entrada do e-mail: {{ emailData }} para verificar a sua conta.
            </p>
            <button type="button" class="success-msg__button" (click)="router.navigate(['home'])">
               Ir para home
            </button>
            <button type="button" class="success-msg__button">Central de ajuda</button>
         </section>
      </div>
   </div>
</section>
