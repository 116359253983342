import { Component, Input } from '@angular/core'
@Component({
   selector: 'app-department-cards',
   templateUrl: './department-cards.component.html',
   styleUrls: ['./department-cards.component.scss'],
})
export class DepartmentCardsComponent {
   @Input() departments: any

   constructor() {}
}
