export const routes: any = [
   {
      title: 'Minha conta',
      childs: [
         {
            link: '../minha-conta/meus-dados',
            name: 'Meus dados',
         },
         {
            link: '../minha-conta/meus-enderecos',
            name: 'Meus endereços',
         },
         {
            link: '../minha-conta/meus-pagamentos',
            name: 'Meus pagamentos',
         },
      ],
   },
   {
      title: 'Compras',
      childs: [
         {
            link: '../compras/minhas-compras',
            name: 'Minhas compras',
         },
         {
            link: '../compras/meus-favoritos',
            name: 'Favoritos',
         },
         {
            link: '../compras/minhas-perguntas',
            name: 'Minhas Perguntas',
         },
      ],
   },
   {
      title: 'Vendas',
      childs: [
         {
            link: '../vendas/anuncios',
            name: 'Anúncios',
         },
         {
            link: '../vendas/minhas-vendas',
            name: 'Vendas',
         },
         {
            link: '../vendas/transacoes',
            name: 'Transações',
         },
         {
            link: '../vendas/perguntas',
            name: 'Perguntas',
         },
      ],
   },
   {
      title: 'Central de Ajuda',
      childs: [
         {
            link: '/ajuda',
            name: 'FAQ',
         },
      ],
   },
]

export const navMenuItems: any[] = [
   { nav: 'Meus dados', rota: 'minha-conta/meus-dados' },
   { nav: 'Meus pagamentos', rota: 'minha-conta/meus-pagamentos' },
   { nav: 'Compras', rota: 'compras' },
   { nav: 'Vendas', rota: 'vendas' },
   { nav: 'Central de ajuda', rota: 'ajuda' },
]
