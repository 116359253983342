import { Component, ElementRef, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { UserResponse } from '@interfaces/user.interface'
import { ELocalStorage, ESessionStorage } from '@enums/localstorage.enum'
import { DepartmentService } from '../../services/department.service'
import { first, Subscription } from 'rxjs'
import { SharedDepartmentsService } from '../../services/shared-departments.service'
import { transition, trigger } from '@angular/animations'
import * as animations from '@animations'
import { AuthService } from '../../services/auth.service'
import { LocalstorageService } from '@helpers/utils/localstorage'
import { ProfileUserService } from '../../services/profile-user.service'
import { navMenuItems, routes } from './utility/RouteTypes'
import { Location } from '@angular/common'
import disableScroll from '@helpers/utils/disableScroll.helper'
import { SharedAuthService } from '@shared/services/shared-auth.service'

@Component({
   selector: 'app-header',
   templateUrl: './header.component.html',
   styleUrls: ['./header.component.scss'],
   animations: [
      trigger('openAnimation', [
         transition(':enter', animations.useHeightInAnimation('0.2s')),
         transition(':leave', animations.useHeightOutAnimation('0.2s')),
      ]),
   ],
})
export class HeaderComponent implements OnInit, OnDestroy {
   @ViewChild('appMenuMobile') appMenuMobile!: ElementRef

   @ViewChild('headerMenu') headerMenu!: ElementRef

   public menuOpened: boolean = false

   public searchInputOpened: boolean = false

   public icon: string = 'search-icon.svg'

   public auth: boolean

   public menuUser: boolean

   public search: string = ''

   public valueAria: string = 'true'

   public userData: UserResponse

   public subscriptions: Subscription[] = []

   public navMenuItems: any = navMenuItems

   public department: any

   public departmentMenu: any

   public depMenu: boolean

   public hasProfileUser: any

   public firstName: string

   public subMenu: boolean

   public pageName: string

   public routes: any = routes

   public hasIdProduct: any

   constructor(
      private renderar: Renderer2,
      private departmentService: DepartmentService,
      private sharedDepartmentsService: SharedDepartmentsService,
      private authService: AuthService,
      private localStorage: LocalstorageService,
      private profileUserService: ProfileUserService,
      private sharedAuthService: SharedAuthService,
      private location: Location,
      private router: Router,
   ) { }

   ngOnInit() {
      let savedToken: string | null | undefined

      savedToken = this.localStorage.getItem(ELocalStorage.USER_TOKEN) || ''

      this.auth = !!savedToken

      this.getProfileUser()

      this.getDepartments()

      this.userData = JSON.parse(this.localStorage.getItem(ELocalStorage.USER_DATA) || '{}')

      const name = this.userData?.name?.split(' ')[0]

      this.firstName = name

      this.verifyHasProductId()
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   getProfileUser() {
      if (!!this.auth) {
         this.subscriptions.push(
            this.profileUserService.getProfileUser().subscribe((response: any) => {
               this.hasProfileUser = response
               this.sharedAuthService.setUserData(response)
               this.localStorage.setItem(
                  ELocalStorage.USER_DATA,
                  JSON.stringify({
                     id: response.id,
                     name: response.name,
                     surname: response.surname,
                     bornDate: response.bornDate,
                     profilePhoto: response.profilePhoto,
                     company: response.company,
                     enableStore: response.enableStore,
                     gender: response.gender,
                     store: response.store,
                     address: response.address,
                  }),
               )
            }),
         )
      }
   }

   getDepartments(): any {
      const path: string = this.location.path()
      const location: boolean = path == '/login' || path == '/cadastre-se'
      if (!location) {
         this.subscriptions.push(
            this.departmentService
               .getDepartments()
               .pipe(first())
               .subscribe((response: any) => {
                  this.sharedDepartmentsService.setDepartments(response)
                  this.departmentMenu = response
                  this.department = response.filter((element: any) => {
                     return !!element.display && !!element.displayOnHome
                  })
               }),
         )
      }
   }

   advertiseRoute() {
      if (!this.auth) {
         this.router.navigate(['/login'])
      } else {
         this.router.navigate(['novo-anuncio'])
      }
   }

   arrowToggle() {
      this.menuUser = !this.menuUser
   }

   arrowToggleDep() {
      this.depMenu = !this.depMenu
   }

   goToProfile() {
      this.router.navigate([
         `vendedor/${this.hasProfileUser?.store[0]?.id}-${this.hasProfileUser?.store[0]?.slug}`,
      ])
      this.menuOpened = !this.menuOpened
   }

   goToFavorite() {
      this.router.navigate(['compras/meus-favoritos'])
   }

   toggleChecked() {
      // this.appMenuMobile.nativeElement.setAttribute('checked', this.menuOpened)
   }

   /**
    * Muda para menu-mobile e troca o valor do Aria
    */

   toggleMobileMenu(): void {
      this.menuOpened = !this.menuOpened
      let _valueAria
      this.menuOpened ? (_valueAria = 'true') : 'false'
      if (this.menuOpened) {
         _valueAria = 'true'
         this.toggleChecked()
         this.renderar.addClass(this.headerMenu.nativeElement, 'fixed')
      } else {
         _valueAria = 'false'
         this.toggleChecked()
         this.renderar.removeClass(this.headerMenu.nativeElement, 'fixed')
      }
      this.resetScrool()
   }

   resetScrool() {
      disableScroll(this.menuOpened)
   }

   toggleSearchBar() {
      this.searchInputOpened = !this.searchInputOpened
      this.icon = !!this.searchInputOpened ? 'arrow-up.svg' : 'search-icon.svg'
      disableScroll(this.searchInputOpened)
   }

   submitSearch(event: any) {
      event.preventDefault()
      const termToSearch = this.search
      this.router.navigate([`/busca/${termToSearch}`])
   }

   searchField(data: any) {
      this.search = data
   }

   close(event: any) {
      if (event) {
         this.menuOpened = false
      } else {
         this.menuOpened = true
      }
   }

   signIn() {
      this.router.navigate(['/login'])
   }

   deslogar() {
      this.authService.logout()
   }

   goToCart() {
      this.router.navigate(['/carrinho'])
   }

   verifyHasProductId() {
      this.hasIdProduct = this.localStorage.getItem(ESessionStorage.PRODUCT_CART)
   }
}
