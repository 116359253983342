<div class="product-card" [title]="title + ' | Moedas.com.br'">
   <div class="product-card__header">
      <i
         #heart
         class="material-icons product-card__like"
         [class.favorite]="isFavorite"
         (click)="setLiked()"
         >{{ isFavorite ? 'favorite' : 'favorite_border' }}</i
      >
      <a href="/produto/{{ id }}-{{ slug }}">
         <figure *ngIf="image; else notImage" class="product-card__image-holder">
            <img [src]="image" [alt]="title" class="product-card__image" />
         </figure>
         <ng-template #notImage>
            <figure class="product-card__image-holder">
               <img src="assets/img/sem-imagem.svg" class="product-card__image" />
            </figure>
         </ng-template>
      </a>
   </div>
   <span class="product-card__conditions" *ngIf="isNewProduct()">Novo</span>
   <div class="product-card__content">
      <a href="/produto/{{ id }}-{{ slug }}">
         <div>
            <strong class="product-card__price">{{ value | currency: 'BRL' }}</strong>
            <h3 class="product-card__title">{{ title | ellipsis: 22 }}</h3>
         </div>
         <span class="product-card__type">{{ type }}</span>
      </a>
      <button class="product-card__buy" (click)="sendProduct()">Comprar agora</button>
   </div>
</div>
