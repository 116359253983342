import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'capitalizecase',
})
export class CapitalizeCasePipe implements PipeTransform {
   transform(value: string | null): string | null {
      if (!value) return null
      const words: RegExp = /\b(?!de|da|do|dos|das|of)[A-zÀ-ú]+/g
      const newVal: string = value.replace(words, match => {
         return match.replace(
            /^\w/,
            word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase(),
         )
      })
      return newVal.charAt(0).toUpperCase() + newVal.substring(1)
   }
}
