import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
   selector: 'card-status',
   templateUrl: './card-status.component.html',
   styleUrls: ['./card-status.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class CardStatusComponent {
   @Input() content: any
   constructor() {}
}
