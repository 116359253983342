import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { Subscription } from 'rxjs'

@Component({
   selector: 'error-message',
   templateUrl: './error-message.component.html',
   styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
   @Input() control: AbstractControl
   @Input() msgs: { [key: string]: string } = {
      required: 'Campo obrigatório',
      minlength: 'Mínimo {number} caracteres',
      maxlength: 'Máximo {number} caracteres',
      haslength: 'Deve conter {number} caracteres',
      email: 'E-mail inválido',
      password: 'Senha Inválida',
      cpfValid: 'CPF Inválido',
      cnpjValid: 'CNPJ Inválido',
      postcodeInvalid: 'CEP Inválido',
      different: 'Senha não coincidem',
      ccardValid: 'Cartão inválido',
      balanceError: 'Valor existente maior que o retirado',
   }
   controlStatusChangesSubscription: Subscription
   errorMessages: { label: string; requiredLength?: any }[] = []
   constructor() {}

   ngOnInit() {
      this.buildErrorMessage()
      this.controlStatusChangesSubscription = this.control?.statusChanges.subscribe(() =>
         this.buildErrorMessage(),
      )
   }
   ngOnDestroy(): void {
      this.controlStatusChangesSubscription?.unsubscribe()
   }

   buildErrorMessage() {
      this.errorMessages = []
      if (this.control?.errors && this.msgs) {
         const errors = Object.keys(this.control.errors)
         errors.forEach(key => {
            this.errorMessages.push({
               label: this.msgs[key],
               // @ts-ignore: Object is possibly 'null'.
               requiredLength: this.control.errors[key]['requiredLength'],
            })
         })
      }
   }
}
