import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ELocalStorage } from '@enums/localstorage.enum'
import { LocalstorageService } from '@helpers/utils/localstorage'

@Component({
   selector: 'app-page-success',
   templateUrl: './page-success.component.html',
   styleUrls: ['./page-success.component.scss'],
})
export class PageSuccessComponent implements OnInit {
   public emailData: any
   constructor(public router: Router, private localStorage: LocalstorageService) {}

   ngOnInit(): void {
      this.getEmail()
   }

   getEmail() {
      this.emailData = JSON.parse(this.localStorage.getItem(ELocalStorage.USER_DATA) || '{}')?.email
   }
}
