<base-dialog title="Quero ser um vendedor">
   <p class="text">
      Faça upload dos arquivos necessários pro seu cadastro. Identidade, CPF e comprovante de residência.
   </p>

   <div class="send-files">
      <form [formGroup]="validaFormGroup" (ngSubmit)="submit()" class="send-files__form">
         <drag-drop-file
            label="Identidade"
            key="indentidade"
            bucket="private"
            (setFile)="setFormData($event, 'ident')"
         ></drag-drop-file>
         <drag-drop-file
            key="cpf"
            label="CPF"
            bucket="private"
            (setFile)="setFormData($event, 'cpf')"
         ></drag-drop-file>
         <drag-drop-file
            label="Comprovante de residência"
            key="comp_residencia"
            bucket="private"
            (setFile)="setFormData($event, 'com_residencia')"
         ></drag-drop-file>

         <div class="send-files__form-bottom">
            <div class="send-files__form-terms">
               <input type="checkbox" formControlName="term" required id="terms" />
               <label for="terms">
                  Li e concordo com os <a href="#">Termos de Uso e Política de Privacidade.</a>
               </label>
            </div>
            <button
               type="submit"
               class="send-files__button btn-loader"
               [class.btn-loader--start]="isLoading"
            >
               Enviar arquivos
            </button>
         </div>
      </form>
   </div>
</base-dialog>
