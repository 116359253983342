import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Department } from '../../core/interface/department.interface'

@Injectable({
   providedIn: 'root',
})
export class SharedDepartmentsService {
   private shared$: BehaviorSubject<Department | any> = new BehaviorSubject<Department | any>(null)

   readonly departments$: Observable<Department | any> = this.shared$.asObservable()

   setDepartments(dep: Department) {
      this.shared$.next(dep)
   }
}
