export enum ELocalStorage {
   USER_TOKEN = 'moedas_token',
   USER_DATA = 'moedas_user_data',
   COMBINE_SELLER = 'combine-seller',
   ID_ADDRESS = 'id_address',
   USER_DELIVERY = 'user_delivery',
}

export enum ESessionStorage {
   PRODUCT_CART = 'product-cart',
   PRODUCT_QUANTITY = 'product-quantity',
   SAVE_CART = 'save-cart',
   CHECKOUT_PRODUCT = 'checkout_product',
   FIRST_STEP = 'first_step',
   SHIPPING_POSTCODE = 'shipping_postcode',
}
