<section class="moedas-faq">
   <div class="moedas-faq__container">
      <div class="moedas-search-faq">
         <img class="detail1" src="assets/img/detailimg.svg" alt="" />
         <div class="moedas-search-faq__container">
            <h1>Como a gente pode te ajudar?</h1>
            <p>Use o campo de busca ou escolha uma categoria</p>
            <div class="search-header">
               <app-search-input
                  (changeSearch)="filter($event.target.value)"
                  [faqInfo]="faqInfo"
               ></app-search-input>
            </div>
         </div>
         <img class="detail2" src="assets/img/detailimg.svg" alt="" />
      </div>

      <div class="moedas-accordion-faq" id="faq">
         <div class="moedas-accordion-faq__container">
            <span class="search-main">
               <app-search-input (changeSearch)="filter($event.target.value)"></app-search-input>
            </span>
            <ng-container *ngFor="let faqDad of faqInfo; let i = index">
               <not-found-message
                  icon="search_off"
                  title="Desculpe, nenhum resultado encontrado."
                  text="Não há informações que correspondem à sua busca.."
                  *ngIf="!(faqInfo[i]?.faqData | filter: filterKeys:true).length && i < 1"
               ></not-found-message>
               <h1
                  class="titulo-faq"
                  *ngIf="(faqInfo[i]?.faqData | filter: filterKeys:true).length"
               >
                  {{ faqDad?.displayName }}
               </h1>
               <dl
                  class="list-faq"
                  *ngFor="
                     let faqChidren of faqInfo[i]?.faqData | filter: filterKeys:true;
                     let o = index
                  "
               >
                  <dt aria-expanded="false" (click)="toggleAccordion(swipe, swipeArrow)">
                     {{ faqChidren?.title }}
                     <img
                        class="arrow-down"
                        #swipeArrow
                        aria-hidden="true"
                        src="assets/img/arrow-down.svg"
                     />
                  </dt>
                  <dd #swipe class="sub-text">
                     {{ faqChidren?.text }}
                  </dd>
               </dl>
            </ng-container>
         </div>
      </div>
      <section class="moedas-leave-to-contact">
         <div class="moedas-leave-to-contact__container">
            <h3>Entre em contato para dúvidas e sugestões</h3>
            <a [routerLink]="['/fale-conosco']">Fale conosco</a>
         </div>
      </section>
   </div>
</section>
