import { transition, trigger } from '@angular/animations'
import {
   Component,
   EventEmitter,
   Input,
   Output,
   TemplateRef,
   ViewEncapsulation,
} from '@angular/core'
import * as animations from '@animations'

@Component({
   selector: 'options-menu',
   templateUrl: './options-menu.component.html',
   styleUrls: ['./options-menu.component.scss'],
   encapsulation: ViewEncapsulation.None,
   animations: [
      trigger('openAnimation', [
         transition(':enter', animations.useHeightInAnimation('0.2s')),
         transition(':leave', animations.useHeightOutAnimation('0.2s')),
      ]),
   ],
})
export class OptionsMenuComponent {
   @Input() content: TemplateRef<any>
   @Input() menuStyle: { [klass: string]: any }
   @Output() setClose = new EventEmitter<boolean>()
   constructor() {}
}
