import { HttpEventType } from '@angular/common/http'

export const convertUnit = (val: number | string): string => {
   const units: string[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
   let l = 0
   let n = parseInt(val.toString(), 10) || 0

   while (n > 1024 && ++l) {
      n = n / 1024
   }

   return n.toFixed(n < 10 && l > 0 ? 1 : 0) + units[l]
}

export const validateSize = (size: number, maxSize: string): boolean => {
   const getSize: number = Number(maxSize.match(/\d+/g))
   const getUnity: any = maxSize.match(/[a-zA-Z]+/g)?.[0].toLowerCase()

   const valueInBytes = convertNumberToBytes(getUnity, getSize)

   if (size <= valueInBytes) {
      return true
   } else {
      return false
   }
}

export const convertNumberToBytes = (unity: string, value: number): number => {
   const unitsForms: any = [
      { unity: 'kb', formula: 1024 },
      { unity: 'mb', formula: Math.pow(1024, 2) },
      { unity: 'gb', formula: Math.pow(1024, 3) },
      { unity: 'tb', formula: Math.pow(1024, 4) },
   ]
   const expoent: any = unitsForms.find((el: any) => el.unity.includes(unity))

   const bytes: number = value * expoent.formula

   return bytes
}

export const maxSpliceFiles = (fileArray: any, max: number): any => {
   let newArray: any[] = []
   if (fileArray.length > max) {
      newArray = fileArray.slice(Math.max(fileArray.length - max, 0))
   } else {
      newArray = fileArray
   }

   return newArray
}
export const maxFiles = (fileArray: any, max: number): boolean => {
   if (fileArray.length > max) {
      return false
   } else {
      return true
   }
}

export const fileAccepted = (fileArray: any, accept: string): boolean => {
   const accepted: string[] = accept.trim().split(', ' || ',')
   return accepted.includes(fileArray.type)
}
export const uploadProgress = (event: any): any => {
   let progress: number = 0
   let response: any
   switch (event.type) {
      case HttpEventType.UploadProgress:
         progress = Math.round((event.loaded / (event.total || 0)) * 100 - 1)
         break
      case HttpEventType.Response:
         progress = 100
         response = event.body
   }
   return { progress: progress, res: response }
}
