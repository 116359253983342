import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'

@Component({
   selector: 'ordenation-menu',
   templateUrl: './ordenation-menu.component.html',
   styleUrls: ['./ordenation-menu.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class OrdenationMenuComponent {
   @Input() search_placeholder: string = 'Pesquise por produto, vendedor ou destinatário'
   @Output() search = new EventEmitter<string>()
   @Output() sort = new EventEmitter<string>()

   public arrowToggle: boolean = false
   public mobileSortableMenu: boolean = false
   public orderByString: string = 'Mais recentes'

   constructor() {}
}
