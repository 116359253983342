import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
   selector: 'app-search-input',
   templateUrl: './search-input.component.html',
   styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
   @Input() faqInfo: unknown
   @Input() placeholder?: string = 'Faça sua pesquisa'
   @Output() changeSearch = new EventEmitter<any>()

   public searchFaq!: any
   public faqs!: any

   constructor() { }
}
