import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { ELocalStorage, ESessionStorage } from '@enums/localstorage.enum'
import { Observable } from 'rxjs'
import { GetValuesInSessionService } from '../services/get-values-in-session.service'

@Injectable({
   providedIn: 'root',
})
export class CartRequestGuard implements CanActivate {
   constructor(
      private router: Router,
      @Inject(PLATFORM_ID) private platformId: any,
      private getValuesInSessionService: GetValuesInSessionService,
   ) {}
   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (isPlatformBrowser(this.platformId)) {
         let idProduct = localStorage.getItem(ESessionStorage.PRODUCT_CART)
         let auth = localStorage.getItem(ELocalStorage.USER_TOKEN)
         if (!idProduct && !auth) {
            this.router.navigate(['/'])
         } else {
            this.getValuesInSessionService.verifyProduct(true)
         }
      }
      return true
   }
}
