import { LoadingService } from './../../services/loading.service'
import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { ProductService } from '@services/product.service'
import { AuthService } from '@shared/services/auth.service'
import * as dayjs from 'dayjs'
import { first, Subscription } from 'rxjs'
import { ESessionStorage } from '@enums/localstorage.enum'
import { LocalstorageService } from '@helpers/utils/localstorage'

@Component({
   selector: 'product-card',
   templateUrl: './product-card.component.html',
   styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent {
   constructor(
      private router: Router,
      private productService: ProductService,
      private authService: AuthService,
      private localstorageService: LocalstorageService,
      private loadingService: LoadingService,
   ) {}

   @Input() title: string
   @Input() conditions: string
   @Input() value: string
   @Input() id: string
   @Input() image: string
   @Input() type: string
   @Input() createdDate: string
   @Input() slug: string
   @Input() slugType: string

   @Input() isFavorite: boolean

   public auth: boolean

   public subscriptions: Subscription[] = []

   isNewProduct() {
      const daysToConsiderIsNew = 2
      const today = dayjs()
      const productDate = dayjs(this.createdDate)
      return today.diff(productDate, 'day') <= daysToConsiderIsNew
   }

   goToProduct(link: string) {
      this.router.navigateByUrl(`/produto/${link}`)
   }

   setLiked() {
      if (this.authService.auth) {
         this.subscriptions.push(
            this.productService
               .setFavorite(this.id)
               .pipe(first())
               .subscribe({
                  next: () => {
                     this.isFavorite = this.isFavorite ? false : true
                  },
                  error: error => {
                     this.isFavorite = false
                     console.error(error)
                  },
               }),
         )
      } else {
         this.router.navigate(['/login'])
      }
   }

   goToDetailType() {
      this.router.navigate(['busca'], {
         queryParams: { 'department.name.keyword': this.slugType },
      })
   }

   sendProduct() {
      this.loadingService.setLoading(true)
      const hasSession = sessionStorage.getItem(ESessionStorage.SAVE_CART)
      if (this.authService.auth) {
         this.localstorageService.setItem(ESessionStorage.PRODUCT_CART, this.id)
         this.localstorageService.setItem(ESessionStorage.PRODUCT_QUANTITY, '1')
         this.localstorageService.setItem(ESessionStorage.FIRST_STEP, 'first')
         this.router.navigate(['/carrinho'])
         if (hasSession) {
            sessionStorage.clear()
         }
      } else {
         this.router.navigate(['/login'])
      }
   }
}
