import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class WindowSizeService {
   private bool$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
   readonly valid_size$: Observable<boolean> = this.bool$.asObservable()
   constructor(private breakpointObserver: BreakpointObserver) {}

   validateSize(breackpoint: string) {
      this.breakpointObserver.observe([`(${breackpoint})`]).subscribe((result: BreakpointState) => {
         this.bool$.next(result.matches)
      })
   }
}
