import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { LocalstorageService } from '@helpers/utils/localstorage'
import { ELocalStorage } from '../../core/enums/localstorage.enum'

@Injectable({
   providedIn: 'root',
})
export class AuthService {
   public auth: boolean

   constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      private localStorage: LocalstorageService,
   ) {
      let savedToken: string | null | undefined

      savedToken = this.localStorage.getItem(ELocalStorage.USER_TOKEN) || ''

      this.auth = !!savedToken
   }

   setLogged() {
      this.auth = true
   }

   logout() {
      if (!!this.auth) {
         this.localStorage.clear()
         sessionStorage.clear()
         location.reload()
      }
   }
}
