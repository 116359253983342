/* istanbul ignore file */
import { Component, Input } from '@angular/core'

@Component({
   selector: 'arrow',
   templateUrl: './arrow-animated.component.html',
   styleUrls: ['./arrow-animated.component.scss'],
})
export class ArrowAnimatedComponent {
   @Input() type: 'left' | 'right' | 'bottom' | 'top' | 'plus' | 'minus'
   @Input() active: boolean
   constructor() {}
}
