<dl class="accordion" *ngIf="type === 'accordion'">
   <dt class="accordion__header" aria-expanded="false" #element (click)="toggleAccordion(element)">
      <ng-content select="[header]"></ng-content>
      <arrow [type]="icon" [active]="opened"></arrow>
   </dt>

   <dd *ngIf="opened" @openAnimation [@.disabled]="animate$ | async">
      <ng-content select="[content]"></ng-content>
   </dd>
</dl>

<ng-container *ngIf="type === 'expand'">
   <div class="expand-text" #content>
      <ng-content select="[text]"></ng-content>

      <button (click)="expandText()" *ngIf="showButton">
         <arrow type="plus" [active]="expandedText"></arrow>
      </button>
   </div>
   <div *ngIf="expandedText">
      <ng-content select="[expanded]"></ng-content>
   </div>
</ng-container>
