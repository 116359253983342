import { environment } from '@environment'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LocalstorageService } from '@helpers/utils/localstorage'
import { ELocalStorage, ESessionStorage } from '@enums/localstorage.enum'

const api = environment.apiUrl

@Injectable({
   providedIn: 'root',
})
export class ProfileUserService {
   constructor(private httpClient: HttpClient, private localStorage: LocalstorageService) { }

   getProfileUser(): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.get<any>(`${api}users/profile`, { headers })
   }

   getDefaultPostcode(): String {
      const userData = JSON.parse(this.localStorage.getItem(ELocalStorage.USER_DATA) as string)
      const defaultAddress = userData.address.find((e: { default: boolean }) => e.default == true)

      return (
         sessionStorage.getItem(ESessionStorage.SHIPPING_POSTCODE) ||
         (defaultAddress.postcode as String)
      )
   }
}
