import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { ELocalStorage } from '@enums/localstorage.enum'
import { Observable } from 'rxjs'
import { LocalstorageService } from '../../helpers/utils/localstorage'

@Injectable({
   providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
   constructor(
      private router: Router,
      private localStorage: LocalstorageService,
      @Inject(PLATFORM_ID) private platformId: any,
   ) {}
   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (isPlatformBrowser(this.platformId)) {
         let itemStr: any = localStorage.getItem(ELocalStorage.USER_TOKEN)
         const item = JSON.parse(itemStr)
         const now = new Date()
         if (now.getTime() > item?.expiry || !item?.expiry) {
            localStorage.clear()
            this.router.navigate(['login'])
            return false
         }
         let userData: any

         userData = JSON.parse(localStorage.getItem(ELocalStorage.USER_DATA) || '{}')
         if (!Object.keys(userData)?.length) {
            this.router.navigate(['login'])
            return false
         } else {
            return true
         }
      }

      return false
   }
}
