import { environment } from '@environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http'
import { Observable, catchError, timeout } from 'rxjs'
import { ApiResponse } from '../interface/api.interface'
import { ProductResponse } from '@interfaces/product-response.interface'
import { ProductRequest } from '@interfaces/product-request.interface'
import { paginatedRequest } from '@interfaces/paginated-request.interface'
import { ordersResponse } from '@interfaces/orders-response.interface'
import { HAS_LOADING } from '@helpers/constants/contexts.helper'
import { ListProduct } from '@interfaces/list-product.interface'
import { ProductFields } from '@interfaces/productfields.interface'
import { ShippingRequest, ShippingResponse } from '@interfaces/shipping.interface'
@Injectable()
export class ProductService {
   readonly apiURL: string = environment.apiUrl
   constructor(private httpClient: HttpClient) {}

   public listProducts(data: paginatedRequest, globalLoading: boolean): Observable<ListProduct> {
      const headers = new HttpHeaders()
      return this.httpClient.post<ListProduct>(`${this.apiURL}products/store`, data, {
         headers,
         context: new HttpContext().set(HAS_LOADING, globalLoading),
      })
   }

   public getProduct(id: string | number): Observable<ApiResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.get<ApiResponse>(`${this.apiURL}products/${id}`, { headers })
   }
   public setFavorite(id: string): Observable<ApiResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.get<ApiResponse>(`${this.apiURL}products/like/${id}`, {
         headers,
      })
   }
   public getFavorites(globalLoading: boolean): Observable<ApiResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.get<ApiResponse>(`${this.apiURL}products/likes/get`, {
         headers,
         context: new HttpContext().set(HAS_LOADING, globalLoading),
      })
   }
   public createProduct(data: ProductRequest): Observable<ProductResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.post<ProductResponse>(`${this.apiURL}products`, data, {
         headers,
      })
   }
   public updateProduct(data: ProductRequest, id: number): Observable<ProductResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.patch<ProductResponse>(`${this.apiURL}products/${id}`, data, {
         headers,
      })
   }
   public setProdSate(id: number, type: string): Observable<boolean> {
      const headers = new HttpHeaders()
      return this.httpClient.get<boolean>(`${this.apiURL}products/${type}/${id}`, {
         headers,
      })
   }

   public getStoreOrders(
      data: paginatedRequest,
      globalLoading: boolean,
   ): Observable<ordersResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.post<ordersResponse>(`${this.apiURL}orders/store`, data, {
         headers,
         context: new HttpContext().set(HAS_LOADING, globalLoading),
      })
   }

   public getAllProductFields(): Observable<ProductFields> {
      const headers = new HttpHeaders()
      return this.httpClient.get<ProductFields>(`${this.apiURL}products/get/fields`, {
         headers,
      })
   }

   public getShipping(data: ShippingRequest): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient
         .post<ShippingResponse[]>(`${this.apiURL}shipping`, data, {
            headers,
         })
         .pipe(
            timeout(20000),
            catchError(e => {
               return [
                  {
                     error: true,
                  },
               ]
            }),
         )
   }
}
