import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'totalValue',
   pure: false,
})
export class TotalValuePipe implements PipeTransform {
   transform(value: any, valueTwo: any): number {
      if (!valueTwo) {
         return value
      } else {
         let comma: any = valueTwo
         return +value + +comma
      }
   }
}
