import {
   animate,
   animation,
   AnimationReferenceMetadata,
   group,
   keyframes,
   style,
   useAnimation,
} from '@angular/animations'

const shrink = animation(
   animate(
      '{{time}}',
      style({
         height: '0px',
         paddingTop: '0px',
         paddingBottom: '0px',
         marginTop: '0px',
         marginBottom: '0px',
      }),
   ),
   { params: { time: '200ms' } },
)
const grow = animation(
   [
      style({
         height: '0px',
         paddingTop: '0px',
         paddingBottom: '0px',
         marginTop: '0px',
         marginBottom: '0px',
      }),
      animate(
         '{{time}}',
         style({
            height: '*',
            paddingTop: '*',
            paddingBottom: '*',
            marginTop: '*',
            marginBottom: '*',
         }),
      ),
   ],
   { params: { time: '200ms' } },
)

const fadeInAnimation = animation(
   [style({ opacity: '0' }), group([animate('{{time}}', style({ opacity: '1' }))])],
   { params: { time: '300ms' } },
)

export function useFadeInAnimation(time: string = '200ms'): AnimationReferenceMetadata {
   return useAnimation(fadeInAnimation, { params: { time } })
}

const fadeOutAnimation = animation(
   [style({ opacity: '1' }), group([animate('{{time}}', style({ opacity: '0' }))])],
   { params: { time: '300ms' } },
)

export function useFadeOutAnimation(time: string = '200ms'): AnimationReferenceMetadata {
   return useAnimation(fadeOutAnimation, { params: { time } })
}

const slideFadeIn = animation(
   [
      style({ opacity: '0', transform: '{{transformDirection}}({{startPos}})' }),
      group([animate('{{time}}', style({ opacity: '1', transform: '*' }))]),
   ],
   { params: { time: '300ms', startPos: '100%', transformDirection: 'translateX' } },
)

export function useSlideFadeInAnimation(
   time: string = '200ms',
   startPos: string = '100%',
   direction: 'horizontal' | 'vertical' = 'horizontal',
): AnimationReferenceMetadata {
   const transformDirection: string = direction === 'horizontal' ? 'translateX' : 'translateY'
   return useAnimation(slideFadeIn, { params: { time, startPos, transformDirection } })
}

const slideFadeOut = animation(
   [
      group([
         useAnimation(shrink, { params: { time: '{{time}}' } }),
         animate(
            '{{time}}',
            style({ opacity: '0', transform: '{{transformDirection}}({{endPos}})' }),
         ),
      ]),
   ],
   { params: { time: '200ms', endPos: '100%', transformDirection: 'translateX' } },
)

export function useSlideFadeOutAnimation(
   time: string = '200ms',
   endPos: string = '100%',
   direction: 'horizontal' | 'vertical' = 'horizontal',
): AnimationReferenceMetadata {
   const transformDirection: string = direction === 'horizontal' ? 'translateX' : 'translateY'
   return useAnimation(slideFadeOut, { params: { time, endPos, transformDirection } })
}

const growIn = animation(
   [
      style({ height: '0px', transform: 'scaleY(0)' }),
      group([
         useAnimation(grow, { params: { time: '{{time}}' } }),
         animate('{{time}}', style({ transform: '*' })),
      ]),
   ],
   { params: { time: '200ms' } },
)

export function useGrowInAnimation(time: string = '200ms'): AnimationReferenceMetadata {
   return useAnimation(growIn, { params: { time } })
}
const shrinkOut = animation(
   group([
      useAnimation(shrink, { params: { time: '{{time}}' } }),
      animate('{{time}}', style({ transform: 'scaleY(0)' })),
   ]),
   { params: { time: '200ms' } },
)

export function useShrinkOutAnimation(time: string = '200ms'): AnimationReferenceMetadata {
   return useAnimation(shrinkOut, { params: { time } })
}

const swingIn = animation(
   [
      style({
         transformOrigin: '50% 0px',
         transform: 'perspective(500px) rotate3d(1, 0, 0, 90deg)',
      }),
      group([
         useAnimation(grow, { params: { time: '200ms' } }),
         animate(
            '{{time}}',
            keyframes([
               style({ transform: 'perspective(500px) rotate3d(1, 0, 0, -70deg)' }),
               style({ transform: 'perspective(500px) rotate3d(1, 0, 0, 40deg)' }),
               style({ transform: 'perspective(500px) rotate3d(1, 0, 0, -15deg)' }),
               style({ transform: 'perspective(500px) rotate3d(1, 0, 0, 0deg)' }),
            ]),
         ),
      ]),
   ],
   { params: { time: '600ms' } },
)

export function useSwingInAnimation(time: string = '600ms'): AnimationReferenceMetadata {
   return useAnimation(swingIn, { params: { time } })
}

const swingOut = animation(
   [
      animate(
         '{{time}}',
         keyframes([
            style([
               {
                  transformOrigin: '50% 0px',
                  transform: 'perspective(500px) rotate3d(1, 0, 0, 0deg)',
               },
               { offset: 0 },
            ]),
            style([{ transform: 'perspective(500px) rotate3d(1, 0, 0, -30deg)' }, { offset: 0.3 }]),
            style([{ transform: 'perspective(500px) rotate3d(1, 0, 0, 90deg)' }, { offset: 1 }]),
         ]),
      ),
      useAnimation(shrink, { params: { time: '200ms' } }),
   ],
   { params: { time: '300ms' } },
)

export function useSwingOutAnimation(time: string = '300ms'): AnimationReferenceMetadata {
   return useAnimation(swingOut, { params: { time } })
}

const bounceInUp = animation(
   [
      style([{ opacity: 0 }]),
      useAnimation(grow, { params: { time: '200ms' } }),
      animate(
         '{{time}} cubic-bezier(0.215, 0.610, 0.355, 1.000)',
         keyframes([
            style([{ opacity: 0, transform: 'translate3d(0, 20px, 0)', offset: 0 }]),
            style([{ opacity: 1, transform: 'translate3d(0, -20px, 0)' }, { offset: 0.5 }]),
            style([{ transform: 'translate3d(0, 10px, 0)' }, { offset: 0.75 }]),
            style([{ transform: 'translate3d(0, -5px, 0)' }, { offset: 0.95 }]),
            style([{ transform: 'translate3d(0, 0, 0)' }, { offset: 1 }]),
         ]),
      ),
   ],
   { params: { time: '400ms' } },
)

export function useBounceInUpAnimation(time: string = '200ms'): AnimationReferenceMetadata {
   return useAnimation(bounceInUp, { params: { time } })
}

const bounceOutDown = animation(
   [
      animate(
         '{{time}}',
         keyframes([
            style([{ transform: 'translate3d(0, -5px, 0)' }, { offset: 0.05 }]),
            style([{ transform: 'translate3d(0, 10px, 0)' }, { offset: 0.25 }]),
            style([{ opacity: 1, transform: 'translate3d(0, -20px, 0)' }, { offset: 0.5 }]),
            style([{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }, { offset: 1 }]),
         ]),
      ),
      useAnimation(shrink),
   ],
   { params: { time: '300ms' } },
)

export function useBounceOutDownAnimation(time: string = '200ms'): AnimationReferenceMetadata {
   return useAnimation(bounceOutDown, { params: { time } })
}

const heightInAnimation = animation(
   [
      style({ overflow: 'hidden', height: '0' }),
      group([animate('{{time}} ease-in-out', style({ overflow: 'hidden', height: '*' }))]),
   ],
   { params: { time: '300ms' } },
)

export function useHeightInAnimation(time: string = '300ms'): AnimationReferenceMetadata {
   return useAnimation(heightInAnimation, { params: { time } })
}
const heightOutAnimation = animation(
   [
      style({ overflow: 'hidden', height: '*' }),
      group([animate('{{time}} ease-in-out', style({ overflow: 'hidden', height: '0' }))]),
   ],
   { params: { time: '300ms' } },
)

export function useHeightOutAnimation(time: string = '300ms'): AnimationReferenceMetadata {
   return useAnimation(heightOutAnimation, { params: { time } })
}
