import { Component } from '@angular/core'

@Component({
   selector: 'loading-spin',
   templateUrl: './loading-spin.component.html',
   styleUrls: ['./loading-spin.component.scss'],
})
export class LoadingSpinComponent {
   constructor() {}
}
