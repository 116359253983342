import { transition, trigger } from '@angular/animations'
import {
   OverlayPositionBuilder,
   Overlay,
   OverlayRef,
   ConnectedPosition,
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import {
   Component,
   Input,
   Directive,
   ElementRef,
   HostListener,
   OnInit,
   TemplateRef,
   ComponentRef,
} from '@angular/core'
import { Placement } from '@enums/placementOverlay.enum'
import { POSITION_MAP } from '@helpers/constants/popover-position'
import { Debounce } from '@helpers/decorators/debounce.helper'
import * as animations from '@animations'
@Component({
   selector: 'app-tooltip',
   template: `
      <div
         class="tooltip {{ tooltipPlacement }}"
         [ngStyle]="tooltipStyles"
         *ngIf="!tooltipDisabled"
         @openAnimation
      >
         <div class="square {{ tooltipPlacement }}" data-testid="tooltip-arrow"></div>
         {{ text }}
         <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
   `,
   styleUrls: ['./tooltip.directive.scss'],
   animations: [
      trigger('openAnimation', [
         transition(':enter', animations.useFadeInAnimation('0.3s')),
         transition(':leave', animations.useFadeOutAnimation('0.3s')),
      ]),
   ],
})
export class TooltipComponent {
   @Input() text: string
   @Input() content: TemplateRef<any>
   @Input() tooltipPlacement: Placement = 'top'
   @Input() tooltipStyles: { [klass: string]: any }
   @Input() tooltipDisabled: boolean = false
}

@Directive({
   selector: '[tooltip]',
})
export class TooltipDirective implements OnInit {
   @Input('tooltip') content: string | TemplateRef<any>
   @Input() tooltipPlacement: Placement = 'top'
   @Input() tooltipStyles: { [klass: string]: any }
   @Input() tooltipDisabled: boolean = false
   @Input() tooltipClickClose: boolean = false
   private overlayRef: OverlayRef

   constructor(
      private overlayPositionBuilder: OverlayPositionBuilder,
      private elementRef: ElementRef,
      private overlay: Overlay,
   ) {}

   ngOnInit() {
      const positionStrategy = this.overlayPositionBuilder
         .flexibleConnectedTo(this.elementRef)
         .withPositions(this.positionStrategy)

      this.overlayRef = this.overlay.create({ positionStrategy })
   }

   get positionStrategy(): ConnectedPosition[] {
      return [POSITION_MAP[this.tooltipPlacement]]
   }

   @HostListener('mouseenter')
   show() {
      const tooltipPortal = new ComponentPortal(TooltipComponent)

      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal)
      tooltipRef.instance.tooltipPlacement = this.tooltipPlacement
      tooltipRef.instance.tooltipStyles = this.tooltipStyles
      tooltipRef.instance.tooltipDisabled = this.tooltipDisabled

      if (typeof this.content === 'string') {
         tooltipRef.instance.text = this.content
      } else {
         tooltipRef.instance.content = this.content
      }

      if (window.innerWidth <= 992) {
         this.hideWithTime()
      }
   }

   @HostListener('mouseout')
   hide() {
      this.overlayRef.detach()
   }

   @Debounce(1000)
   private hideWithTime() {
      this.hide()
   }

   @HostListener('click')
   clickClose() {
      this.tooltipClickClose && this.hide()
   }
}
