import { Component, Inject } from '@angular/core'
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

@Component({
   selector: 'toast',
   templateUrl: './toast.component.html',
   styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
   public content: any = {
      type: 'error',
      title: 'Ocorreu um erro',
      text: 'Verifique os campos e tente novamente',
   }
   constructor(
      public snackBarRef: MatSnackBarRef<ToastComponent>,
      @Inject(MAT_SNACK_BAR_DATA) data: any,
   ) {
      if (data) {
         Object.assign(this.content, data)
      }
   }

   onClose(): void {
      this.snackBarRef.dismiss()
   }
}
