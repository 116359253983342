import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthenticatedGuard } from '@guards/authenticated.guard'
// Guards
import { DisableGuardGuard } from '@guards/disable.guard'
// Components
import { CartRequestGuard } from './core/pages/cart/guard/cart-request.guard'
import { MoedasFaqComponent } from './core/pages/moedas-faq/moedas-faq.component'
import { PageSuccessComponent } from '@shared/components/pages/page-success/page-success.component'
import { SuccessGuard } from '@guards/success.guard'

const routes: Routes = [
   {
      path: '',
      loadChildren: () =>
         import('./core/pages/home/moedas-home/moedas-home.module').then(m => m.MoedasHomeModule),
      canActivate: [],
   },
   {
      path: 'home',
      redirectTo: '',
   },
   {
      path: 'login',
      loadChildren: () =>
         import('./core/components/auth/moedas-auth/moedas-auth.module').then(
            m => m.MoedasAuthModule,
         ),
      canActivate: [DisableGuardGuard],
   },
   {
      path: 'produto',
      loadChildren: () => import('./core/pages/product/product.module').then(m => m.ProductModule),
   },
   {
      path: 'busca',
      loadChildren: () => import('./core/pages/search/search.module').then(m => m.SearchModule),
   },
   {
      path: 'ajuda',
      component: MoedasFaqComponent,
   },
   {
      path: 'cadastre-se',
      loadChildren: () =>
         import('./core/pages/register/register.module').then(m => m.RegisterModule),
      canActivate: [DisableGuardGuard],
   },
   {
      path: 'cadastro-sucesso',
      component: PageSuccessComponent,
      canActivate: [SuccessGuard],
   },
   {
      path: 'vendedor',
      loadChildren: () =>
         import('./core/pages/store-profile/store-profile.module').then(m => m.ProfileModule),
   },
   {
      path: 'fale-conosco',
      loadChildren: () =>
         import('./core/pages/fale-conosco/fale-conosco.module').then(m => m.FaleConoscoModule),
   },
   {
      path: 'minha-conta',
      loadChildren: () =>
         import('./core/pages/my-account/my-account.module').then(m => m.MyAccountModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'compras',
      loadChildren: () =>
         import('./core/pages/purchases/purchases.module').then(m => m.PurchasesModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'vendas',
      loadChildren: () => import('./core/pages/sales/sales.module').then(m => m.SalesModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'carrinho',
      loadChildren: () => import('./core/pages/cart/cart/cart.module').then(m => m.CartModule),
      canActivate: [CartRequestGuard],
   },
   {
      path: 'novo-anuncio',
      loadChildren: () =>
         import('./core/pages/novo-anuncio/novo-anuncio.module').then(m => m.NovoAnuncioModule),
   },
   {
      path: 'quem-somos',
      loadChildren: () =>
         import('./core/pages/generic-pages/generic.module').then(m => m.GenericModule),
   },
   {
      path: 'termos-de-uso',
      loadChildren: () =>
         import('./core/pages/generic-pages/generic.module').then(m => m.GenericModule),
   },
   {
      path: 'politica-de-privacidade',
      loadChildren: () =>
         import('./core/pages/generic-pages/generic.module').then(m => m.GenericModule),
   },
   {
      path: 'recuperar-senha',
      loadChildren: () =>
         import('./core/pages/password-recovery/password-recovery.module').then(
            m => m.PasswordRecoveryModule,
         ),
   },
   {
      path: 'confirmacao-email/:hash',
      loadChildren: () =>
         import('./core/pages/email-confirmed/email-confirmed.module').then(
            m => m.EmailConfirmedModule,
         ),
   },
   // {
   //    path: '**',
   //    redirectTo: '',
   // },
]

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         initialNavigation: 'enabledBlocking',
         scrollPositionRestoration: 'enabled',
      }),
   ],
   exports: [RouterModule],
})
export class AppRoutingModule {}
