import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { environment } from '@environment'
import { AddressRequest, AddressResponse } from '@interfaces/address.interface'
import { CCardResponse, CCcardRequest } from '@interfaces/cCard.interface'
import { UserResponse } from '@interfaces/user.interface'
import { Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class MyAccountService {
   constructor(
      private httpClient: HttpClient,
      @Inject('moedasEnv')
      private moedasEnv: typeof environment,
   ) { }

   public setFavorite(route: string, id: number): Observable<{ success: string }> {
      const headers = new HttpHeaders()
      return this.httpClient.get<{ success: string }>(
         `${this.moedasEnv.apiUrl}${route}/favorite/${id}`,
         {
            headers,
         },
      )
   }

   //ADDRESS

   public getAddress(): Observable<AddressResponse[]> {
      const headers = new HttpHeaders()
      return this.httpClient.get<AddressResponse[]>(`${this.moedasEnv.apiUrl}users-addresses`, {
         headers,
      })
   }
   public favoriteAddress(id: number): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.get<any[]>(`${this.moedasEnv.apiUrl}users-addresses/${id}`, {
         headers,
      })
   }
   public postAddress(data: AddressRequest): Observable<AddressResponse> {
      const headers = new HttpHeaders()
      const params = new HttpParams()
      return this.httpClient.post<AddressResponse>(
         `${this.moedasEnv.apiUrl}users-addresses`,
         data,
         {
            headers,
            params,
         },
      )
   }
   public deleteAddress(id: any): Observable<AddressResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.delete<AddressResponse>(
         `${this.moedasEnv.apiUrl}users-addresses/${id}`,
         {
            headers,
         },
      )
   }

   //USER

   public changePassword(data: { newPassword: string }): Observable<UserResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.post<UserResponse>(
         `${this.moedasEnv.apiUrl}users/password/change`,
         data,
         {
            headers,
         },
      )
   }
   public becomeSeller(data: any): Observable<any> {
      const headers = new HttpHeaders()
      return this.httpClient.post<any>(`${this.moedasEnv.apiUrl}stores-requests`, data, {
         headers,
      })
   }

   //CREDIT CARDS

   public getCCards(): Observable<CCardResponse[]> {
      const headers = new HttpHeaders()
      return this.httpClient.get<CCardResponse[]>(`${this.moedasEnv.apiUrl}cards`, {
         headers,
      })
   }
   public createCCard(data: CCcardRequest): Observable<CCardResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.post<CCardResponse>(`${this.moedasEnv.apiUrl}cards`, data, {
         headers,
      })
   }

   public deleteCCard(id: any): Observable<string> {
      const headers = new HttpHeaders()
      return this.httpClient.delete<string>(`${this.moedasEnv.apiUrl}cards/${id}`, {
         headers,
      })
   }
}
