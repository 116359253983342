import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core'
import { LoadingService } from '@shared/services/loading.service'
import { AnimationOptions } from 'ngx-lottie'
import { Subscription } from 'rxjs'

@Component({
   selector: 'moedas-loading',
   templateUrl: './moedas-loading.component.html',
   styleUrls: ['./moedas-loading.component.scss'],
})
export class MoedasLoadingComponent implements OnInit, OnDestroy {
   public lottieConfig: AnimationOptions
   public subscriptions: Subscription[] = []
   public loading: boolean = false
   constructor(public loadingService: LoadingService, private element: ElementRef) {
      this.lottieConfig = {
         // Types available: loader-box, loader-line, loader-line, loader-square
         path: '/assets/animations/loading-moedas.json',
         renderer: 'svg',
         loop: true,
         autoplay: true,
      }
   }

   ngOnInit() {
      this.subscriptions.push(
         this.loadingService.loading$.subscribe((status: boolean) => {
            this.loading = status
            if (status) {
               this.element.nativeElement.closest('body').style.overflow = 'hidden'
            } else {
               this.element.nativeElement.closest('body').style.overflow = 'auto'
            }
         }),
      )
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }
}
