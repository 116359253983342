<div class="search-head">
   <app-search-input
      (changeSearch)="this.search.emit($event.target.value)"
      [placeholder]="search_placeholder"
   ></app-search-input>

   <div class="search-head__search-order">
      <p style="width: 100px">Ordenar</p>
      <span class="search-head__search-order-button">
         <button [menu]="order" (toggle)="arrowToggle = !arrowToggle">
            {{ orderByString }}
            <arrow [type]="'bottom'" [active]="arrowToggle"></arrow>
         </button>
      </span>
   </div>
   <div class="search-head__search-order-mobile" [class.active]="mobileSortableMenu">
      <ul>
         <li
            (click)="sort.emit('desc'); orderByString = 'Mais recentes'; mobileSortableMenu = false"
         >
            Mais recentes
            <span class="select" [ngClass]="{ check: orderByString === 'Mais recentes' }"></span>
         </li>
         <li (click)="sort.emit('asc'); orderByString = 'Mais antigos'; mobileSortableMenu = false">
            Mais antigos
            <span class="select" [ngClass]="{ check: orderByString === 'Mais antigos' }"></span>
         </li>
      </ul>
      <button (click)="mobileSortableMenu = !mobileSortableMenu">
         <img src="assets/img/ordenation.svg" alt="ordenação icone" aria-hidden="true" />
         Ordenar
      </button>
   </div>
</div>

<ng-template #order>
   <li (click)="sort.emit('desc'); orderByString = 'Mais recentes'">Mais recentes</li>
   <li (click)="sort.emit('asc'); orderByString = 'Mais antigos'">Mais antigo</li>
</ng-template>
