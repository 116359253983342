/* eslint-disable complexity */
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'status',
})
export class StatusPipe implements PipeTransform {
   transform(value: string, type: string = 'product'): string {
      let realVelue: string

      if (type === 'transaction') {
         switch (value) {
            case 'success':
               realVelue = 'Aprovado'
               break
            case 'approved':
               realVelue = 'Aprovado'
               break
            case 'awaiting':
               realVelue = 'Aguardando'
               break
            case 'rejected':
               realVelue = 'Rejeitado'
               break
            case 'unknown':
               realVelue = 'Status desconhecido'
               break

            default:
               'unknown'
               realVelue = 'Status desconhecido'
               break
         }
      } else if (type === 'shipping') {
         switch (value) {
            case 'delivered':
               realVelue = 'Entregue'
               break
            case 'inMovement':
               realVelue = 'Em trânsito'
               break
            case 'pickedUp':
               realVelue = 'Coletado'
               break

            default:
               'unknown'
               realVelue = 'Status desconhecido'
               break
         }
      } else {
         switch (value) {
            case 'active':
               realVelue = 'ativo'
               break
            case 'canceled':
               realVelue = 'cancelado'
               break
            case 'paused':
               realVelue = 'pausado'
               break
            case 'disapproved':
               realVelue = 'reprovado'
               break
            case 'pendent':
               realVelue = 'pendente'
               break
            case 'suspended':
               realVelue = 'encerrado'
               break
            case 'finished':
               realVelue = 'finalizado'
               break
            case 'unknown':
               realVelue = 'Status desconhecido'
               break

            default:
               'awaitingApproval'
               realVelue = 'aguardando'
               break
         }
      }
      return realVelue
   }
}
