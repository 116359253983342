<footer class="moedas-footer hide-print">
   <section class="moedas-footer__container">
      <div class="moedas-footer__container-column1">
         <img [routerLink]="['/home']" src="assets/img/logo-footer.svg" alt="Moedas.com.br Logo" />
      </div>
      <div class="moedas-footer__container-column2">
         <ul>
            <li>
               <a aria-label="Ir para quem somos" [routerLink]="['/quem-somos']">
                  Sobre o Moedas.com.br
               </a>
            </li>
            <li>
               <a (click)="advertiseRoute()"> Faça o seu anúncio </a>
            </li>
         </ul>
      </div>
      <div class="moedas-footer__container-column3">
         <ul>
            <li>
               <a [routerLink]="['/cadastre-se']"> Cadastre-se </a>
            </li>
            <li>
               <a href="#" *ngIf="auth; else notAuth" (click)="deslogar()"> Logout </a>
               <ng-template #notAuth>
                  <a [routerLink]="['/login']" aria-label="Ir para Login"> Login </a>
               </ng-template>
            </li>
         </ul>
      </div>
      <div class="moedas-footer__container-column4">
         <ul>
            <li>
               <a aria-label="Ira para fale conosco" [routerLink]="['/fale-conosco']">
                  Fale conosco
               </a>
            </li>
            <li>
               <a aria-label="Ir para o FAQ" [routerLink]="['/ajuda']"> FAQ </a>
            </li>
         </ul>
      </div>
      <div class="moedas-footer__container-column5">
         <ul>
            <li>
               <a aria-label="Ir para termo de uso" [routerLink]="['/termos-de-uso']">
                  Termo de uso
               </a>
            </li>
            <li>
               <a
                  aria-label="Ir para política de privacidade"
                  [routerLink]="['/politica-de-privacidade']"
               >
                  Política de privacidade
               </a>
            </li>
         </ul>
      </div>
   </section>
   <address>
      <p>
         Copyright © 2023 Moedas.com.br<br />
         MOEDAS.COM.BR ATIVIDADE DE INTERNET LTDA - 45.860.718/0001-96
      </p>
   </address>
</footer>
