import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MyAccountService } from '@services/my-account.service'
import { DialogRef } from '@shared/services/dialog/dialog-refs'
import { first, Subscription } from 'rxjs'

@Component({
   selector: 'app-send-documents',
   templateUrl: './send-documents.component.html',
   styleUrls: ['./send-documents.component.scss'],
})
export class SendDocumentsComponent implements OnInit, OnDestroy {
   public validaFormGroup: FormGroup
   public formData: any
   public isLoading: boolean = false

   public subscriptions: Subscription[] = []
   constructor(private myAccountService: MyAccountService, private dialogRef: DialogRef) {}

   ngOnInit() {
      this.buildForm()
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   private buildForm() {
      this.validaFormGroup = new FormGroup({
         ident: new FormControl('', [Validators.required]),
         cpf: new FormControl('', [Validators.required]),
         com_residencia: new FormControl('', [Validators.required]),
         term: new FormControl('', [Validators.required]),
      })
   }

   setFormData(file: any, type: string) {
      this.validaFormGroup.get(type)?.setValue(file?.[0] || '')
   }

   submit() {
      if (this.validaFormGroup.valid) {
         this.isLoading = true
         const payload = {
            documents: [
               {
                  name: 'cpf',
                  url: this.validaFormGroup.value.cpf,
               },
               {
                  name: 'compResidencia',
                  url: this.validaFormGroup.value.com_residencia,
               },
               {
                  name: 'rg',
                  url: this.validaFormGroup.value.ident,
               },
            ],
         }

         this.subscriptions.push(
            this.myAccountService
               .becomeSeller(payload)
               .pipe(first())
               .subscribe({
                  next: _ => {
                     this.dialogRef.close(true)
                     this.isLoading = false
                  },
                  error: error => {
                     console.error('Seller', error)
                     this.isLoading = false
                  },
               }),
         )
      }
   }
}
