import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { Router, CanActivate, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { ELocalStorage } from '@enums/localstorage.enum'
import { isPlatformBrowser } from '@angular/common'

@Injectable({
   providedIn: 'root',
})
export class DisableGuardGuard implements CanActivate {
   constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: any) {}
   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (isPlatformBrowser(this.platformId)) {
         let token: string | null | undefined

         token = localStorage.getItem(ELocalStorage.USER_TOKEN)
         if (!token) {
            return true
         } else {
            this.router.navigate(['home'])
         }
      }

      return false
   }
}
