import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import {
   ActivatedRouteSnapshot,
   CanActivate,
   Router,
   RouterStateSnapshot,
   UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class SuccessGuard implements CanActivate {
   constructor(private router: Router, private location: Location) {}
   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
   ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const leavingPage = this.location.path() === '/cadastro-sucesso'
      if (leavingPage) {
         this.router.navigate(['home'])
         return false
      }

      return true
   }
}
