import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { environment } from '@environment'
import { HAS_LOADING } from '@helpers/constants/contexts.helper'
import { MOEDAS_TOKEN } from '@helpers/constants/providers.helper'
import { RegisterAddress, RegisterResponse, UserResquest } from '@interfaces/register.interface'
import { LoadingService } from '@shared/services/loading.service'
import { Observable } from 'rxjs'
import { ApiResponse } from '../interface/api.interface'
import { AuthRequest, AuthResponse } from '../interface/auth.interface'
@Injectable({
   providedIn: 'root',
})
export class MoedasHomeService {
   constructor(
      private httpClient: HttpClient,
      @Inject(MOEDAS_TOKEN)
      private moedasEnv: typeof environment,
      private loadingService: LoadingService,
   ) { }

   public getHome(): Observable<ApiResponse> {
      const headers = new HttpHeaders()
      return this.httpClient.get<ApiResponse>(`${this.moedasEnv.apiUrl}home`, {
         headers,
         context: new HttpContext().set(HAS_LOADING, true),
      })
   }

   public getAuth(data: AuthRequest): Observable<AuthResponse> {
      const headers = new HttpHeaders()
      const params = new HttpParams()
      return this.httpClient.post<AuthResponse>(`${this.moedasEnv.apiUrl}auth/login`, data, {
         headers,
         params,
      })
   }

   public postResgisterUser(data: UserResquest): Observable<RegisterResponse> {
      const headers = new HttpHeaders()
      const params = new HttpParams()
      return this.httpClient.post<RegisterResponse>(`${this.moedasEnv.apiUrl}users/signup`, data, {
         headers,
         params,
      })
   }
   public postResgisterAddress(data: RegisterResponse): Observable<RegisterAddress> {
      const headers = new HttpHeaders()
      const params = new HttpParams()
      return this.httpClient.post<RegisterAddress>(
         `${this.moedasEnv.apiUrl}users-addresses`,
         data,
         {
            headers,
            params,
         },
      )
   }
}
