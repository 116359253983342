import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Subscription } from 'rxjs'
import { MoedasFaqService } from '../../services/moedas-faq.service'

@Component({
   selector: 'app-moedas-faq',
   templateUrl: './moedas-faq.component.html',
   styleUrls: ['./moedas-faq.component.scss'],
})
export class MoedasFaqComponent implements OnInit, OnDestroy {
   @ViewChild('swipeArrow') swipeArrow: ElementRef

   public accordion: boolean = false
   public subscriptions: Subscription[] = []

   public faqInfo!: any
   public filterKeys: any = { title: '', text: '' }

   public hasFiltered!: any

   constructor(private moedasFaqService: MoedasFaqService, private title: Title) {}

   ngOnInit(): void {
      this.title.setTitle('Ajuda | Moedas.com.br')
      this.getListFaq()
   }
   /* istanbul ignore next */
   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   getListFaq(): void {
      this.subscriptions.push(
         this.moedasFaqService
            .getFaq()
            .pipe()
            .subscribe({
               next: (response: any) => {
                  this.faqInfo = response
               },
               error: error => {
                  console.error('Ajuda', error)
               },
            }),
      )
   }

   filter(event: string) {
      this.filterKeys.title = event
      this.filterKeys.text = event
   }

   toggleAccordion(o: HTMLElement, swipeArrow: HTMLElement): void {
      swipeArrow.classList.toggle('rotate')
      o.classList.toggle('sub-text--open-accordion')
      this.accordion = !this.accordion
      o.setAttribute('aria-expanded', this.accordion.toString())
   }
}
