import { Component, Input } from '@angular/core'

@Component({
   selector: 'app-breadcrumb',
   templateUrl: './breadcrumb.component.html',
   styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
   @Input() public hierarchy: Array<any> = []

   hierarchyResult = [
      {
         name: 'Home',
         link: '/',
         current: false,
      },
   ]

   ngAfterInit() {}

   constructor() {
      if (this.hierarchy && this.hierarchy.length) {
         this.hierarchy.forEach(item => this.hierarchyResult.push(item))
      }
   }
}
