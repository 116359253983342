import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
   selector: 'burguer-menu',
   templateUrl: './burguer-menu.component.html',
   styleUrls: ['./burguer-menu.component.scss'],
})
export class BurguerMenuComponent {
   @Input() active: boolean = false
   @Output() opened = new EventEmitter<any>()

   onBurgerClicked() {
      this.active = !this.active
      this.opened.emit()
   }
}
