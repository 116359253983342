import { Pipe, PipeTransform } from '@angular/core'

interface SelectData {
   value: string | number
   viewValue: string | number
}

@Pipe({
   name: 'selectInstallments',
})
export class SelectInstallmentsPipe implements PipeTransform {
  transform(
    value: any,
    param1: string = 'installments',
    param2: string = 'tax',
    param3: string = 'value',
    param4: string = 'finalValueStrig',
    param5: string = 'valueString'
 ): SelectData {
    return value?.map((el: any) => {
       const selectValue = {
          value: el[param1],
          viewValue: `<p style="padding: 5px 0">${el[param1]}x de ${el[param5]} ${
            el[param2] > 0 ? ' com juros' : 'sem juros'
         }</small></p>`
       }
       return selectValue
    })
 }
}

// finalValue:3290
// installments:1
// tax:0
// value:3290
